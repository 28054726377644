<template>
  <div
    class="events-count d-flex align-items-center justify-content-center h-100"
    @click="redirectToStaffCalendar(event.staff, event.start_time)"
  >
    <span
      :class="event.type === 'booking' ? 'bg-primary text-white' : 'text-black '"
      class="d-flex align-items-center justify-content-center"
      >{{ event.count }}</span
    >
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations } from 'vuex'

export default {
  name: 'EventCount',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapMutations({
      setSelectedStaff: 'calendar/SET_SELECTED_STAFF'
    }),
    redirectToStaffCalendar(staff, date = moment().format('YYYY-MM-DD')) {
      this.setSelectedStaff(staff)
      this.$router.push(`/calendar/staff/day/${moment(date).format('YYYY-MM-DD')}`)
    }
  }
}
</script>

<style lang="scss" scoped></style>
