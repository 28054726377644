<template>
  <b-modal
    id="booking-create"
    header-class="modal-header p-0 border-bottom-0"
    body-class="filterModalBody px-0 px-md-3 pt-0 pb-1"
    footer-class="px-0 py-0 d-block"
    centered
    @shown="isVisible"
    @hide="confirmMessage"
    @hidden="resetData"
  >
    <!--  no-close-on-backdrop -->
    <template v-slot:modal-header="{ close }">
      <div class="top-block w-100">
        <div class="d-flex pt-1-5 justify-content-between">
          <div class="w-100 d-flex justify-content-around"></div>
          <font-awesome-icon
            :icon="['far', 'times']"
            class="icon-close-mobile mr-1-5 text-gray"
            @click="confirmMessage"
          ></font-awesome-icon>
        </div>
      </div>
    </template>
    <form v-if="loadBookingParams">
      <div class="col-12 text-small">
        <div class="col-12 d-flex justify-content-center mb-2">
          <h4>
            {{ $t('register-client.new-booking') }}
          </h4>
        </div>
        <div class="col px-0">
          <div>
            <div class="centered-icons">
              <div class="group-option-title mt-2">
                <label class="group-label font-weight-medium mb-0 poppins-font fz-12 mb-1">
                  {{ $t('booking.forms.label.whom') }}
                </label>
              </div>
              <div class="row text-center mb-2">
                <div v-for="genderOption in adultGenderOptions" :key="genderOption.id" class="col common-icon">
                  <label
                    class="w-100 position-relative btn-params mb-0"
                    :class="{
                      active: orderFields.gender && orderFields.gender.value === genderOption.value && !isChildOption
                    }"
                  >
                    <input
                      :id="`btn-radios-${genderOption.id}`"
                      v-model="orderFields.gender"
                      type="radio"
                      name="radio-1"
                      class="btn-checkbox"
                      autocomplete="off"
                      :value="genderOption"
                    />
                    <span class="btn btn-checkbox btn-block gender-icons-btn">
                      <i
                        class="d-none d-md-inline-block"
                        :class="{
                          'btn-params--lady-b icon': genderOption.value === 'Lady',
                          'btn-params--gents-b icon': genderOption.value === 'Gentleman'
                        }"
                      ></i>
                      <span class="pl-d-1 text-small">{{ $t(`common.whom_key.${genderOption.value}`) }}</span>
                    </span>
                  </label>
                </div>
                <div class="col-auto col-md">
                  <label
                    class="w-100 position-relative btn-params mb-0"
                    :class="{ active: isChildOption }"
                    @click="openChildOptions()"
                  >
                    <span
                      class="btn btn-checkbox btn-block px-3 gender-icons-btn"
                      :class="{ 'active-background': isChildOption }"
                    >
                      <i class="btn-params--child-b icon d-none d-md-inline-block"></i>
                      <span class="pl-md-1 pt-0-2 text-small">{{ $t('common.forms.button.child') }}</span>
                    </span>
                  </label>
                </div>
              </div>
              <div v-if="isChildOption" class="form-group">
                <label class="group-label font-weight-medium mb-0 poppins-font mb-1">
                  {{ $t('common.forms.label.child_info') }}
                </label>
                <div class="row text-center">
                  <div class="col-12 col-md-5 py-0">
                    <div class="row">
                      <div
                        v-for="childOption in childGenderOptions"
                        :key="childOption.id"
                        class="col-6 px-md-0-8 child-option-label"
                      >
                        <label class="w-100 mb-0 pr-md-0">
                          <input
                            id="btn-radios-3"
                            v-model="orderFields.gender"
                            type="radio"
                            name="radio-2"
                            class="btn-checkbox"
                            autocomplete="off"
                            :value="childOption"
                          />
                          <span class="btn btn-checkbox btn-block px-md-0-5 px-0">
                            <span class="text-small">{{ $t(`common.whom_key.${childOption.value}`) }}</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <AgeSlider
                    :age-value="orderFields.age ? orderFields.age.value : 1"
                    :age-values="ageValues"
                    @set-age="setAge"
                  ></AgeSlider>
                </div>
              </div>
            </div>
            <div class="col px-0 group-order-field py-0 my-1-5">
              <div class="group-option-title">
                <label class="group-label font-weight-medium mb-0 poppins-font fz-12 mb-1">
                  {{ $t('booking.forms.label.service') }}
                </label>
              </div>
              <div class="row text-center">
                <div class="col-12 servicesMultiselect">
                  <Multiselect
                    v-model="orderFields.service"
                    :options="serviceOptions"
                    :searchable="true"
                    :multiple="true"
                    :group-select="true"
                    :close-on-select="true"
                    :show-labels="false"
                    track-by="title"
                    label="title"
                    :open-direction="'bottom'"
                    :disabled="orderFields.gender === null"
                    placeholder="Service"
                    @search-change="filterServices"
                  >
                    <template slot="caret" slot-scope="{ toggle }">
                      <div
                        class="multiselect__select d-flex align-items-center justify-content-center"
                        @mousedown.prevent.stop="toggle()"
                      >
                        <font-awesome-icon class="text-gray" :icon="['far', 'angle-down']"></font-awesome-icon>
                      </div>
                    </template>
                  </Multiselect>
                </div>
              </div>
            </div>
            <div v-if="showHaircutParams" class="col-12 group-order-field py-0 pl-0-3 pr-0 mb-1-5">
              <label class="group-label font-weight-medium mb-0 poppins-font fz-12 mb-1">
                {{ $t('booking.forms.label.length') }}
              </label>

              <div class="row text-center">
                <div v-for="hairLengthOption in hairLengthOptions" :key="hairLengthOption.id" class="col px-md-0-8">
                  <label class="w-100 mb-0">
                    <input
                      :id="`hairlenght-${hairLengthOption.id}`"
                      v-model="orderFields.length"
                      type="radio"
                      name="radio-3"
                      class="btn-checkbox"
                      autocomplete="off"
                      :value="hairLengthOption"
                    />
                    <span class="btn btn-checkbox btn-block">
                      <span class="text-small">{{ $t(`common.length_key.${hairLengthOption.value}`) }}</span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex px-0">
              <div class="col-6 group-order-field py-0 pl-0 mb-1-5">
                <div class="group-option-title mb-1">
                  <label class="group-label font-weight-medium mb-0 poppins-font fz-12">
                    {{ $t('booking.forms.label.day') }}
                  </label>
                </div>
                <div class="row text-center">
                  <div class="col-12 pl-1">
                    <Multiselect
                      v-model="orderFields.date"
                      :options="dateOptions"
                      :searchable="false"
                      :select-label="''"
                      :allow-empty="false"
                      :disabled="
                        orderFields.gender === null ||
                          (orderFields.length === null && showHaircutParams) ||
                          orderFields.service.length === 0 ||
                          (orderFields.service && !orderFields.service.length)
                      "
                      placeholder="Day"
                      @input="changeDate"
                    >
                      <template slot="caret" slot-scope="{ option, toggle }">
                        <div
                          class="multiselect__select d-flex align-items-center justify-content-center"
                          @mousedown.prevent.stop="toggle()"
                        >
                          <font-awesome-icon class="text-gray" :icon="['far', 'angle-down']"></font-awesome-icon>
                        </div>
                      </template>

                      <template slot="singleLabel" slot-scope="option" class="bookingSelect">
                        <div class="option__desc multiselect-text-color multiselect-choosen-position">
                          <span class="pt-1">{{ option.option | dateFormat }}</span>
                        </div>
                      </template>

                      <template slot="option" slot-scope="option">
                        <div class="option__desc multiselect-text-color">
                          <span>{{ option.option }}</span>
                        </div>
                      </template>
                    </Multiselect>
                  </div>
                </div>
              </div>
              <!-- STEP 4 END -->
              <!-- STEP 5 START -->
              <div class="col-6 group-order-field py-0 mb-1-5">
                <div class="group-option-title mb-1">
                  <label class="group-label font-weight-medium mb-0 poppins-font fz-12">
                    {{ $t('booking.forms.label.specialist') }}
                  </label>
                </div>
                <div class="row text-center">
                  <div class="col-12 pr-0">
                    <Multiselect
                      v-model="orderFields.staff"
                      :options="staffOptions"
                      :searchable="false"
                      :select-label="''"
                      :allow-empty="false"
                      :disabled="orderFields.service.length === 0 || orderFields.date === null"
                      placeholder="Specialist"
                    >
                      <template slot="caret" slot-scope="{ option, toggle }">
                        <div
                          class="multiselect__select d-flex align-items-center justify-content-center"
                          @mousedown.prevent.stop="toggle()"
                        >
                          <font-awesome-icon class="text-gray" :icon="['far', 'angle-down']"></font-awesome-icon>
                        </div>
                      </template>

                      <template slot="singleLabel" slot-scope="option" class="bookingSelect">
                        <div class="option__desc multiselect-text-color multiselect-choosen-position">
                          <div class="d-flex">
                            <div class="pr-1">
                              <img
                                v-if="!option.option.avatar"
                                src="@/assets/images/default/default_avatar.png"
                                alt=""
                                class="booking-multiselect-image"
                              />
                              <img v-else :src="option.option.avatar.path" alt="" class="booking-multiselect-image" />
                            </div>
                            <div>
                              <span v-if="option.option.fullName !== undefined" class="pt-1 text-truncate">
                                {{ option.option.fullName }}
                              </span>
                              <span v-else class="pt-1 text-truncate"
                                >{{ option.option.user.first_name }} {{ option.option.user.last_name }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>

                      <template slot="option" slot-scope="option">
                        <div class="option__desc multiselect-text-color">
                          <div class="d-flex">
                            <div class="pr-1">
                              <img
                                v-if="!option.option.avatar"
                                src="@/assets/images/default/default_avatar.png"
                                alt=""
                                class="booking-multiselect-image"
                              />
                              <img v-else :src="option.option.avatar.path" alt="" class="booking-multiselect-image" />
                            </div>
                            <div>
                              <span class="text-truncate"
                                >{{ option.option.user.first_name }} {{ option.option.user.last_name }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </Multiselect>
                  </div>
                </div>
              </div>
            </div>
            <!-- STEP 5 END -->

            <!-- STEP 6 START -->

            <div class="col-12 group-order-field py-0 px-0 mb-1-5">
              <div class="group-option-title mb-1">
                <label class="group-label font-weight-medium mb-0 poppins-font fz-12">
                  {{ $t('booking.forms.label.time') }}
                </label>
              </div>
              <div class="row text-center">
                <div class="col-12 d-flex align-items-center">
                  <div class="col-6 pl-0">
                    <Multiselect
                      v-model="orderFields.start_time"
                      :options="workingHoursOptions"
                      :searchable="false"
                      :select-label="''"
                      :allow-empty="false"
                      :disabled="
                        !orderFields.staff ||
                          orderFields.service.length === 0 ||
                          !orderFields.date ||
                          !orderFields.gender
                      "
                      placeholder="Start time"
                      @input="changeTime"
                    >
                      <template slot="caret" slot-scope="{ option, toggle }">
                        <div
                          class="multiselect__select d-flex align-items-center justify-content-center"
                          @mousedown.prevent.stop="toggle()"
                        >
                          <font-awesome-icon class="text-gray" :icon="['far', 'angle-down']"></font-awesome-icon>
                        </div>
                      </template>

                      <template slot="singleLabel" slot-scope="option" class="bookingSelect">
                        <div class="option__desc multiselect-text-color multiselect-choosen-position">
                          <span class="pt-1">{{ option.option | timeFormat }}</span>
                        </div>
                      </template>

                      <template slot="option" slot-scope="option">
                        <div class="option__desc multiselect-text-color">
                          <span>{{ option.option | timeFormat }}</span>
                        </div>
                      </template>
                    </Multiselect>
                  </div>
                  <div class="col-6 px-0 text-left">
                    <span v-if="orderFields.end_time" class="text-nowrap pl-1 ml-auto mr-0">
                      {{ $t('booking.forms.label.end_time') }}
                      <span class="text-uppercase">{{ orderFields.end_time | timeFormat }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <template v-slot:modal-footer="{ close }">
      <div class="col-12 mx-0 mb-4 pl-0-3 px-0 pt-1-5 pb-1 border-top border-bottom d-flex justify-content-center">
        <span class="h5 pr-1">{{ $t('register-client.total-price') }}:</span>
        <span v-if="combinationData" class="h5 text-primary">
          <span>{{ combinationData.formatedPrice }} CHF</span>
        </span>
      </div>
      <div class="row px-1 px-md-4 mb-3  mt-0 justify-content-center">
        <div class="col-auto col-md-4">
          <button class="btn btn-outline-secondary-custom btn-block collapsed py-0-8" @click="back">
            <span class="fz14">
              {{ $t('register-client.back') }}
            </span>
          </button>
        </div>
        <div class="col">
          <button
            :disabled="
              !orderFields.gender ||
                orderFields.service.length === 0 ||
                !orderFields.date ||
                !orderFields.staff ||
                !orderFields.start_time ||
                !allowClearPreloadedData ||
                !combinationData ||
                (showHaircutParams && !orderFields.length)
            "
            class="btn btn-block btn-primary py-0-8"
            @click="submitForm"
          >
            <span class="text-nowrap fz14">
              {{ $t('register-client.create-booking') }}
            </span>
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AgeSlider from '@/components/AgeSlider'
import Multiselect from 'vue-multiselect'
import { pick, isEqual } from 'lodash'
import moment from 'moment'

export default {
  components: {
    AgeSlider,
    Multiselect
  },
  filters: {
    dateFormat(val) {
      return moment(val).format('MMMM DD, YYYY')
    },
    timeFormat(val) {
      return val.substring(val.indexOf(' ') + 1)
    }
  },
  props: {
    addedClient: {
      type: Object,
      default: null
    },
    selectedClient: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isChildOption: false,
      emptyDateChoosenData: {},
      combinationData: {},
      dateValue: null,
      isNewUser: false,
      loadBookingParams: false,
      allowClearPreloadedData: false,
      searchValue: '',
      orderFields: {
        gender: null,
        length: null,
        service: [],
        age: null,
        parameter_values: [],
        start_time: null,
        end_time: null,
        full_date: null,
        staff: null,
        date: null,
        status: 'Reserved',
        paid_by: 'Manual',
        products_status: null
      },
      showConfirmAlert: true,
      savedPrice: null,
      createSuccessMessage: {
        type: this.$t('booking.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.created'
      },
      updateSuccessMessage: {
        type: this.$t('booking.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.updated'
      },
      declineSuccessMessage: {
        type: this.$t('booking.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.decline'
      },
      closeSuccessMessage: {
        type: this.$t('booking.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.closed'
      }
    }
  },
  watch: {
    isChildOption(newVal) {
      if (newVal && !this.orderFields.age) {
        this.orderFields.age = this.parametersValues.age.values[0]
      } else {
        this.orderFields.age = null
      }
    },
    orderFields: {
      deep: true,
      handler(newVal) {
        if (newVal.gender && (newVal.gender.value === 'Lady' || newVal.gender.value === 'Gentleman')) {
          this.isChildOption = false
          this.orderFields.age = null
        }
      }
    },
    'orderFields.age': {
      deep: true,
      handler() {
        if (this.allowClearPreloadedData) {
          this.resetCombinationParams()
        }
      }
    },
    'orderFields.gender': {
      deep: true,
      handler() {
        this.orderFields.service = []
        this.orderFields.length = null

        if (this.allowClearPreloadedData) {
          this.resetCombinationParams()
        }
      }
    },
    'orderFields.staff': {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && !isEqual(newVal, oldVal)) {
          this.fetchSalonCombination()
        }
      }
    },
    'orderFields.service': {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.length) {
          const { gender, date, staff } = this.orderFields
          if (gender && date && staff && !this.showHaircutParams) this.fetchSalonCombination()
        } else {
          this.orderFields.length = null

          if (this.allowClearPreloadedData) {
            this.resetCombinationParams()
          }
        }
      }
    },
    'orderFields.length': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          if (this.allowClearPreloadedData) {
            this.resetCombinationParams()
          }

          const { gender, service, date, staff } = this.orderFields
          if (gender && service && date) this.fetchSalonStaff()
          if (gender && service && date && staff) this.fetchSalonCombination()
        }
      }
    },
    'orderFields.date': {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          const { gender, service, date } = this.orderFields

          if (gender && service && date) this.fetchSalonStaff()
        }
      }
    },
    'orderFields.start_time': {
      deep: true,
      handler(newVal) {
        if (!newVal) this.orderFields.end_time = null
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'booking-create') {
        if (this.accountInfo.salon_id) {
          this.loadParams().then(() => {
            this.loadBookingParams = true
            this.setPreloadedData()
          })
        }
      }
    })

    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'booking-create') {
        this.loadBookingParams = false
      }
    })
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      choosenEmptyDay: 'calendar/getChoosenEmptyDay',
      parametersValues: 'haircutParams/getParametersValues',
      servicesListByType: 'services/getServicesListByType',
      staffListByParams: 'salonsStaff/getStaffListByParams'
    }),
    computedOrderFields: function() {
      return Object.assign({}, this.orderFields)
    },
    serviceOptions() {
      if (!this.searchValue) {
        return this.orderFields.gender ? this.servicesListByType(this.orderFields.gender.value) : []
      } else {
        return this.orderFields.gender
          ? this.servicesListByType(this.orderFields.gender.value).filter(service =>
              service.title.toLowerCase().includes(this.searchValue.toLowerCase())
            )
          : []
      }
    },
    hairLengthOptions() {
      return this.parametersValues.hairLength.values
    },
    adultGenderOptions() {
      return [this.parametersValues.genders.values[0], this.parametersValues.genders.values[1]]
    },
    childGenderOptions() {
      return [this.parametersValues.genders.values[2], this.parametersValues.genders.values[3]]
    },
    ageValues() {
      return this.parametersValues.age.values
    },
    showHaircutParams() {
      if (!this.orderFields.service) return false

      let services = this.orderFields.service.filter(service => service.service_category_haircut_params > 0)

      return services.length > 0
    },

    dateOptions() {
      let dateStart = moment()
      let dateEnd = moment().add(1, 'M')
      let timeValues = []

      while (dateEnd > dateStart || dateStart.format('D') === dateEnd.format('D')) {
        timeValues.push(dateStart.format('MMMM DD, YYYY'))
        dateStart.add(1, 'day')
      }

      return timeValues
    },
    workingHoursOptions() {
      let workingHours = {}
      if (this.staffListByParams) {
        this.staffListByParams.forEach(el => {
          if (el.workingHours) workingHours = el.workingHours
        })
      }
      let formatedWorkingHours = []

      if (workingHours !== undefined) {
        Object.values(workingHours).forEach(time => {
          formatedWorkingHours.push(time)
        })
      }
      return formatedWorkingHours
    },
    requestParams() {
      const { gender, age, length, service, date, staff } = this.orderFields

      const requetConditionByHairLegth = gender && service.length && length && date
      const requetConditionWithoutHairLegth = gender && service.length && date && !this.showHaircutParams
      const requetConditionByHairLegthAndAge = gender && age && service.length && length && date
      const requetConditionWithoutHairLegthAndAge = gender && service.length && date && !this.showHaircutParams

      if (
        requetConditionByHairLegth ||
        requetConditionWithoutHairLegth ||
        requetConditionByHairLegthAndAge ||
        requetConditionWithoutHairLegthAndAge
      ) {
        let staffParams = (staffParams = [gender.id])
        if (length !== null) staffParams.push(length.id)
        if (age !== null) staffParams.push(age.id)

        let payload = {
          services: service ? service.map(el => el.id) : [],
          parameters: staffParams,
          salon_id: this.accountInfo.salon_id,
          book_date: date
        }

        if (staff) payload.staff_id = staff.id || staff.staff_id || staff.manager_id

        return payload
      }

      return null
    },
    staffOptions() {
      return this.orderFields.gender && this.staffListByParams ? this.staffListByParams : []
    }
  },
  beforeDestroy() {
    clearInterval(this.staffSelectInterval)
  },
  methods: {
    ...mapActions({
      getListAllStaffByParams: 'salonsStaff/getListAllByParams',
      getSalonCombination: 'salons/getSalonCombination',
      createBooking: 'booking/createBooking',
      fetchParamsValues: 'haircutParams/getParamsValues',
      getServicesListBySalonId: 'services/getServicesListBySalonId',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    ...mapMutations({
      setChoosenDayData: 'calendar/SET_CHOOSEN_DAY_DATA',
      clearChoosenDayData: 'calendar/CLEAR_CHOOSEN_DAY_DATA',
      setCreatedBooking: 'calendar/SET_CREATED_BOOKING'
    }),
    async loadParams() {
      await Promise.all([this.fetchParamsValues(), this.getServicesListBySalonId(this.accountInfo.salon_id)])

      return true
    },
    filterServices(val) {
      this.searchValue = val
    },
    isVisible() {
      this.showConfirmAlert = true
      this.resetDataWhenNoUser()
      this.registerStaffSelectInterval()
    },
    openChildOptions() {
      this.isChildOption = true
      this.orderFields.gender = null
    },
    setPreloadedData() {
      const { staff, date, start_time } = this.choosenEmptyDay

      this.orderFields.date = date
      this.orderFields.staff = { ...staff }
      this.orderFields.start_time = start_time
    },
    resetCombinationParams() {
      this.combinationData = null
      this.orderFields.end_time = null
    },
    resetStaffBookingParams() {
      this.orderFields.staff = null
      this.orderFields.start_time = null
      this.orderFields.end_time = null
      this.resetCombinationParams()
    },
    changeDate(val) {
      this.orderFields.date = moment(val).format('YYYY-MM-DD')
    },
    changeTime(val) {
      this.orderFields.end_time = moment(val, 'HH:mm')
        .add(this.combinationData.duration, 'minutes')
        .format('YYYY-MM-DD H:mm')
    },
    registerStaffSelectInterval() {
      this.staffSelectInterval = setInterval(() => {
        this.fetchSalonStaff()
      }, 1500)
    },
    fetchSalonStaff() {
      const { staff, start_time } = this.orderFields

      if (this.requestParams) {
        this.getListAllStaffByParams(this.requestParams)
          .then(result => {
            this.allowClearPreloadedData = true
            const items = result.data.items || result.data

            if (items.length === 0) {
              this.resetStaffBookingParams()
            } else {
              const selectedStaff = items.find(item => item.id === (staff.id || staff.staff_id || staff.manager_id))

              if (selectedStaff) {
                let timeIsAvaliable = false

                if (selectedStaff.workingHours) {
                  this.orderFields.staff.workingHours = selectedStaff.workingHours

                  timeIsAvaliable = selectedStaff.workingHours.includes(start_time)
                }

                if (!timeIsAvaliable) {
                  this.orderFields.start_time = null
                  this.orderFields.end_time = null
                }
              } else {
                this.resetStaffBookingParams()
              }
            }
          })
          .catch(() => {})
      }
    },
    fetchSalonCombination() {
      const { staff, start_time } = this.orderFields

      if (this.requestParams && staff) {
        this.getSalonCombination(this.requestParams)
          .then(data => {
            if (data) {
              this.combinationData = pick(data, [
                'id',
                'price',
                'default_price',
                'app_fee',
                'stripe_app_fee',
                'stripe_owner_fee',
                'duration'
              ])

              let price = parseFloat(data.price.replace(/\,/g, ''), 10)
              let default_price = parseFloat(data.default_price.replace(/\,/g, ''), 10)
              let discount_price =
                data.discount_price !== undefined
                  ? parseFloat(data.discount_price.replace(/\,/g, ''), 10)
                  : default_price
              let app_fee = parseFloat(data.app_fee.replace(/\,/g, ''), 10)
              let stripe_app_fee = parseFloat(data.stripe_app_fee.replace(/\,/g, ''), 10)
              let stripe_owner_fee = parseFloat(data.stripe_owner_fee.replace(/\,/g, ''), 10)

              let formatedPrice = data.discount_price !== undefined ? Number(discount_price) : Number(default_price)
              let combination = data.combinations.map(el => Number(el.id))
              this.combinationData = {
                ...this.combinationData,
                ...{ price: price },
                ...{ default_price: default_price },
                ...{ app_fee: app_fee },
                ...{ stripe_app_fee: stripe_app_fee },
                ...{ stripe_owner_fee: stripe_owner_fee },
                ...{ formatedPrice: formatedPrice }
              }
              this.combinationData.combinations = combination

              if (start_time) {
                this.changeTime(start_time)
              }
            } else {
              this.combinationData = null
            }
          })
          .catch(() => {})
      }
    },
    bookingPayloadData() {
      const { paid_by, status, date, staff, start_time } = this.orderFields

      const payloadData = {
        booking: {
          ...this.combinationData,
          ...{ salon_id: this.accountInfo.salon_id },
          ...{ description: this.addedClient ? this.addedClient.note : this.selectedClient.note },
          ...{ salon_staff_id: staff.id || staff.staff_id || staff.manager_id },
          ...{ status: status },
          ...{ paid_by: paid_by },
          ...{
            start_time: `${date} ${start_time}`
          },
          ...{ combinations: this.combinationData.combinations },
          ...{ parameters: this.requestParams.parameters },
          ...{ email: this.addedClient ? this.addedClient.email : this.selectedClient.email },
          ...{ default_price: this.combinationData.formatedPrice }
        }
      }

      this.$delete(payloadData.booking, 'id')

      if (this.addedClient) {
        if (this.addedClient.register) {
          payloadData.user = {
            ...{ first_name: this.addedClient.firstName },
            ...{ last_name: this.addedClient.lastName }
          }
          payloadData.email = this.addedClient.email
          if (this.addedClient.phone) payloadData.user.phone = this.addedClient.phone
        }
      }
      if (this.selectedClient) {
        payloadData.user = {
          ...{ first_name: this.selectedClient.first_name },
          ...{ last_name: this.selectedClient.last_name },
          ...{ user_id: this.selectedClient.id }
        }
        payloadData.email = this.selectedClient.email
        if (this.selectedClient.phone) payloadData.user.phone = this.selectedClient.phone
      }

      return payloadData
    },

    submitForm() {
      if (!this.invalidFormData) {
        let payloadData = this.bookingPayloadData()
        this.createBooking(payloadData)
          .then(data => {
            this.setSuccess(this.createSuccessMessage)
            this.showConfirmAlert = false
            this.$bvModal.hide('booking-create')
            this.resetData()
            this.setCreatedBooking(data)
          })
          .catch(errors => {
            if (errors.code === 426) {
              this.setError({
                type: 'error',
                messageI18Key: 'common.alerts.actions.global_error'
              })
            } else if (errors.code === 425) {
              this.setError({
                type: 'error',
                messageI18Key: 'booking.alert.time_is_selected_error'
              })
            } else {
              for (let property in errors.data.messages) {
                if (property === 'email') {
                  this.$validator.errors.add({
                    field: 'email',
                    msg: this.$t('common.error.wrong_email')
                  })
                }
              }
            }
          })
      }
    },
    back(e) {
      this.confirmMessage(e).then(result => {
        if (result) {
          if (this.addedClient) {
            this.$bvModal.hide('booking-create')
            this.$bvModal.show('add-client-modal')
          } else {
            this.$bvModal.hide('booking-create')
            this.$bvModal.show('client-search-modal')
          }
        }
      })
    },
    resetData() {
      this.isChildOption = false
      this.orderFields = {
        gender: null,
        length: null,
        service: [],
        age: null,
        staff: null,
        end_time: null,
        parameter_values: [],
        start_time: null,
        full_date: null,
        date: null,
        status: 'Reserved',
        paid_by: 'Manual',
        products_status: null
      }
      this.combinationData = null
      this.allowClearPreloadedData = false
      clearInterval(this.staffSelectInterval)
    },
    resetDataWhenNoUser() {
      if (!this.addedClient && !this.selectedClient) {
        this.$bvModal.hide('booking-create')
        this.$bvModal.show('client-info-modal')
        this.resetData()
      }
    },
    confirmMessage(e) {
      if (this.showConfirmAlert) {
        e.preventDefault()
        const h = this.$createElement
        const messageVNode = h('div', { class: ['foobar'] }, [
          h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [this.$t('register-client.sure')])
        ])

        return this.$bvModal
          .msgBoxConfirm([messageVNode], {
            centered: true,
            size: 'md',
            footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
            bodyClass: ' pb-4 px-4',
            cancelVariant: 'light',
            cancelTitle: `${this.$t('alert_message.cancel_title')}`
          })
          .then(value => {
            if (value) {
              this.showConfirmAlert = false
              this.resetData()
              this.$bvModal.hide('booking-create')

              return value
            }
          })
          .catch(error => {
            return error
          })
      }
    },
    setAge(val) {
      this.orderFields.age = val
    }
  }
}
</script>
