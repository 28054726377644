<template>
  <div>
    <div class="top-block w-100 mb-1-5 ml-sm-2-5">
      <div class="d-flex justify-content-between">
        <div class="w-100 d-flex justify-content-around"></div>
        <b-button variant="link" class="p-0" @click="close()">
          <font-awesome-icon :icon="['far', 'times']" class="icon-close-mobile text-gray"></font-awesome-icon>
        </b-button>
      </div>
    </div>
    <h6 class="mb-2 w-100 text-center">
      {{ $t('salon-days-off.breadcrumbs-add-label-modal') }}
      <span class="d-block">
        ({{
          weekendId ? $t('salon-days-off.breadcrumbs-update-label') : $t('salon-days-off.breadcrumbs-create-label')
        }})
      </span>
    </h6>

    <form class="form-row mb-2 mb-4">
      <div class="col-12 mb-1-5">
        <div class="col-12 px-0">
          <label for="title" class="poppins-font text-black mb-0-5 font-weight-medium">
            {{ $t('salon-days-off.form-label.title') }}
          </label>
          <div class="input-group col-12 px-0">
            <input
              :id="'title'"
              v-model="formFields.title"
              v-validate="fieldValidationRule + '|max:100'"
              :data-vv-as="$t('salon-days-off.form-label.title')"
              type="text"
              class="calendar-inputs-height form-control w-100 px-1-5"
              :name="'title'"
              :placeholder="`${$t('salon-days-off.title_placeholder')}`"
            />
          </div>
        </div>

        <span v-if="errors.has('title')" class="error-message mt-0-5">{{ errors.first('title') }}</span>
      </div>
      <div class="col-12 mb-1-5">
        <label for="staff" class="poppins-font text-black mb-0-5 font-weight-medium">
          {{ $t('salon-days-off.form-label.appointment') }}
        </label>
        <div class="form-row">
          <div class="col-12">
            <MultiSelect
              v-model="formFields.staff"
              :options="formattedStaffList"
              :searchable="false"
              :allow-empty="false"
              :close-on-select="true"
              :show-labels="true"
              :open-direction="'bottom'"
              label="title"
              :placeholder="this.$t('market.sort-by')"
            >
              <template slot="caret" slot-scope="{ toggle }">
                <div
                  class="multiselect__select d-flex align-items-center justify-content-center"
                  @mousedown.prevent.stop="toggle()"
                >
                  <font-awesome-icon class="text-gray" :icon="['far', 'angle-down']"></font-awesome-icon>
                </div>
              </template>
              <template slot="singleLabel" slot-scope="option" class="bookingSelect pl-0">
                <div class="d-flex">
                  <button v-if="option.option.id === 0" class="btn barber-button py-0 px-0-5">
                    <i class="barber-icon fa-w-14"></i>
                  </button>
                  <div v-else>
                    <img
                      v-if="option.option.avatar"
                      :src="option.option.avatar.path"
                      class="staff-avatar-small staff-avatar-dayoff-select"
                      alt
                    />
                    <img
                      v-else
                      src="@/assets/images/default/default_avatar.png"
                      class="staff-avatar-small staff-avatar-dayoff-select"
                      alt
                    />
                  </div>
                  <span class="pl-1 pt-0-2 text-nowrap text-truncate">{{ option.option.fullName }}</span>
                </div>
              </template>

              <template slot="option" slot-scope="option">
                <div class="option__desc multiselect-text-color">
                  <div class="d-flex">
                    <button v-if="option.option.id === 0" class="btn barber-button py-0 px-0-5">
                      <i class="barber-icon fa-w-14"></i>
                    </button>
                    <div v-else>
                      <img
                        v-if="option.option.avatar"
                        :src="option.option.avatar.path"
                        class="staff-avatar-small staff-avatar-dayoff-select"
                        alt
                      />
                      <img
                        v-else
                        src="@/assets/images/default/default_avatar.png"
                        class="staff-avatar-small staff-avatar-dayoff-select"
                        alt
                      />
                    </div>
                    <span class="pl-1 pt-0-2 text-nowrap text-truncate">{{ option.option.fullName }}</span>
                  </div>
                </div>
              </template>
            </MultiSelect>
          </div>
        </div>
        <span v-if="errors.has('staff')" class="error-message mt-0-5">{{ errors.first('staff') }}</span>
      </div>

      <div class="col-12 mb-1-5">
        <label class="checkbox mb-0 pl-3 pt-0-2">
          <input v-model="activatedFullDay" type="checkbox" class="activateWeekend" />
          <i class="input-helper"></i>
          <div class="text-black text-small poppins-font text-nowrap" style="line-height: 24px;">
            {{ $t('salon-days-off.form-label.day_day') }}
          </div>
        </label>
      </div>

      <div class="col-12 mb-1-5">
        <label class="poppins-font text-black mb-0-5 font-weight-medium">
          {{ $t('salon-days-off.form-label.date') }}
        </label>
        <div class="form-row justify-content-center">
          <div class="col-4">
            <div class="input-group mr-0-5 position-relative">
              <MultiSelect
                v-model="formFields.date"
                :options="dateList"
                :searchable="false"
                :allow-empty="false"
                :close-on-select="true"
                :show-labels="true"
                :open-direction="'bottom'"
                label="title"
                :placeholder="this.$t('market.sort-by')"
              >
                <template slot="caret" slot-scope="{ toggle }">
                  <div
                    class="multiselect__select d-flex align-items-center justify-content-center"
                    @mousedown.prevent.stop="toggle()"
                  >
                    <font-awesome-icon class="text-gray" :icon="['far', 'angle-down']"></font-awesome-icon>
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="option" class="bookingSelect">
                  <span class="text-nowrap text-truncate">{{ option.option }}</span>
                </template>

                <template slot="option" slot-scope="option">
                  <div class="option__desc multiselect-text-color">
                    <span>{{ option.option }}</span>
                  </div>
                </template>
              </MultiSelect>
            </div>

            <span v-if="errors.has('date')" class="error-message mt-0-5 w-100">
              {{ errors.first('date') }}
            </span>
          </div>
          <div class="col">
            <div class="d-flex flex-nowrap ml-2 mr-auto">
              <div class="input-group mr-0-5 position-relative small-input">
                <select
                  v-model="formFields.start_hours"
                  v-validate="!activatedFullDay ? fieldValidationRule : ''"
                  :data-vv-as="$t('salon-days-off.form-label.date')"
                  :disabled="activatedFullDay"
                  name="start_hours"
                  class="form-control w-100 pl-1-5  text-center calendar-inputs-height py-0"
                  style="font-size:14px;"
                >
                  <option v-for="(option, key) in startHoursList" :key="`start_hours_${key}`" :value="option">{{
                    option
                  }}</option>
                </select>
              </div>
              <div class="input-group mr-0-5 position-relative small-input">
                <select
                  v-model="formFields.start_minutes"
                  v-validate="!activatedFullDay ? fieldValidationRule : ''"
                  :data-vv-as="$t('salon-days-off.form-label.date')"
                  :disabled="activatedFullDay"
                  name="start_minutes"
                  class="form-control w-100 pl-1-5 text-center calendar-inputs-height py-0"
                  style="font-size:14px;"
                >
                  <option v-for="(option, key) in startMinutetsList" :key="`start_minutes${key}`" :value="option">{{
                    option
                  }}</option>
                </select>
              </div>

              <div class="input-group mx-0 position-relative small-input">
                <span class="my-auto mx-auto text-gray">—</span>
              </div>

              <div class="input-group mr-0-5 position-relative small-input">
                <select
                  v-model="formFields.end_hours"
                  v-validate="!activatedFullDay ? fieldValidationRule : ''"
                  :data-vv-as="$t('salon-days-off.form-label.date')"
                  :disabled="activatedFullDay"
                  name="end_hours"
                  class="form-control w-100 pl-1-5 text-center calendar-inputs-height py-0"
                  style="font-size:14px;"
                >
                  <option v-for="(option, key) in endHoursList" :key="`start_hours_${key}`" :value="option">{{
                    option
                  }}</option>
                </select>
              </div>
              <div class="input-group position-relative small-input">
                <select
                  v-model="formFields.end_minutes"
                  v-validate="!activatedFullDay ? fieldValidationRule : ''"
                  :data-vv-as="$t('salon-days-off.form-label.date')"
                  :disabled="activatedFullDay"
                  name="end_minutes"
                  class="form-control w-100 pl-1-5 text-center calendar-inputs-height py-0"
                  style="font-size:14px;"
                >
                  <option v-for="(option, key) in endMinutetsList" :key="`start_hours_${key}`" :value="option">{{
                    option
                  }}</option>
                </select>
              </div>
            </div>
            <span
              v-if="
                errors.has('start_hours') ||
                  errors.has('start_minutes') ||
                  errors.has('end_hours') ||
                  errors.has('end_minutes')
              "
              class="error-message mt-0-5 w-100"
            >
              {{
                errors.first('start_hours') ||
                  errors.first('start_minutes') ||
                  errors.first('end_hours') ||
                  errors.first('end_minutes')
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <label class="poppins-font text-black mb-0-5 font-weight-medium">
          {{ $t('salon-days-off.form-label.description') }}
        </label>
        <div class="input-group">
          <textarea
            v-model="formFields.description"
            v-validate="'max:255'"
            :data-vv-as="$t('salon-days-off.form-label.description')"
            name="description"
            class="form-control"
            rows="10"
          ></textarea>
        </div>
        <span v-if="errors.has('description')" class="error-message mt-0-5 w-100">
          {{ errors.first('description') }}
        </span>
      </div>
    </form>

    <div class="row justify-content-center">
      <div class="col-auto col-md-4">
        <b-button variant="outline-secondary-custom" block class="py-0-8" @click="close()">
          <span class="fz14">
            {{ $t('calendar-book-dayoff-create.close') }}
          </span>
        </b-button>
      </div>
      <div v-if="weekendId" class="col-auto">
        <b-button variant="checkbox" class="px-1-5 py-0-8" @click="deleteWeekend()">
          <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14 mr-0-5"></font-awesome-icon>

          <span class="fz14">
            {{ $t('common.delete-btn') }}
          </span>
        </b-button>
      </div>
      <div class="col">
        <b-button variant="primary" block class="py-0-8" @click="submitForm()">
          <span class="fz14">
            {{ $t('salon-days-off.save-changes') }}
          </span>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import TimeConstants from '@/_constants/time.js'
import moment from 'moment/src/moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MultiSelect from 'vue-multiselect'
export default {
  components: {
    MultiSelect
  },
  props: {
    calendarType: {
      type: String,
      default: 'custom-daily'
    }
  },
  data() {
    return {
      now: moment().format('YYYY-MM-DD'),
      loadParams: false,
      serviceLoaded: false,
      submitProcess: false,
      activatedFullDay: true,
      createSuccessMessage: {
        type: this.$t('salon-days-off.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.created'
      },
      updateSuccessMessage: {
        type: this.$t('salon-days-off.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.updated'
      },
      deletedSuccessMessage: {
        type: this.$t('salon-days-off.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.deleted'
      },
      fieldValidationRule: 'required',
      hoursList: TimeConstants.HOURS_LIST,
      minutesList: TimeConstants.MINUTES_LIST,
      formFields: {
        title: '',
        staff: 0,
        start_date: '',
        start_hours: '',
        start_minutes: '',
        end_date: '',
        end_hours: '',
        end_minutes: '',
        description: '',
        date: ''
      },
      fullStaff: null
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      staffList: 'salonsStaff/getStaffList',
      getChoosenEmptyDay: 'calendar/getChoosenEmptyDay',
      weekendId: 'calendar/getSelectedDayOffId'
    }),
    formattedStaffList() {
      return [{ fullName: this.$t('salon-days-off.all_salon'), id: 0 }, ...this.staffList]
    },
    dateList() {
      let dateStart = moment()
      let dateEnd = moment().add(1, 'M')
      let timeValues = []

      while (dateEnd > dateStart || dateStart.format('D') === dateEnd.format('D')) {
        timeValues.push(dateStart.format('MMM D, YYYY'))
        dateStart.add(1, 'day')
      }

      return timeValues
    },
    startHoursList() {
      return this.hoursList
    },
    endHoursList() {
      return this.hoursList.filter(hour => hour >= this.formFields.start_hours)
    },
    startMinutetsList() {
      let minMinuteStart = 0
      let maxMinuteEnd = 45
      let lastHourStart = this.startHoursList[this.startHoursList.length - 1]
      let lastHourEnd = this.hoursList[this.hoursList.length - 1]
      let firstHourEnd = this.hoursList[0]

      return this.calculateStartMinutes(
        this.formFields.start_hours,
        lastHourStart,
        lastHourEnd,
        firstHourEnd,
        minMinuteStart,
        maxMinuteEnd
      )
    },
    endMinutetsList() {
      let minMinuteStart = 0
      let maxMinuteEnd = 45
      let lastHourEnd = this.hoursList[this.hoursList.length - 1]

      return this.calculateEndMinutes(
        this.formFields.end_hours,
        this.formFields.start_hours,
        lastHourEnd,
        this.formFields.start_minutes,
        maxMinuteEnd
      )
    }
  },
  mounted() {
    if (this.accountInfo.salon_id) {
      this.getStaffListBySalonId(this.accountInfo.salon_id)
    }

    if (this.calendarType && this.calendarType === 'custom-daily' && !this.weekendId) this.activatedFullDay = false

    if (this.weekendId) {
      this.fetchDayOffDetailsById(this.weekendId).then(response => {
        const { title, description, staff_id, manager_id, date, start_time, end_time } = response
        this.formFields.title = title
        this.formFields.description = description

        this.staffList.forEach(el => {
          if ((el.staff_id && el.staff_id === staff_id) || (el.manager_id && el.manager_id === manager_id))
            this.formFields.staff = el
        })

        if (!this.formFields.staff) this.formFields.staff = this.formattedStaffList[0]

        this.formFields.date = moment(date).format('MMM D, YYYY')

        if (start_time || end_time) {
          this.activatedFullDay = false
          this.formFields.start_hours = this.calcHours(start_time)
          this.formFields.start_minutes = this.calcMinutes(start_time)
          this.formFields.end_hours = this.calcHours(end_time)
          this.formFields.end_minutes = this.calcMinutes(end_time)
        } else {
          this.activatedFullDay = true
        }
      })
    } else {
      this.setDayoffDataFromCalendar()
    }
  },
  methods: {
    ...mapActions({
      deleteSingleDayOffById: 'dayoffs/deleteSingleDayOffById',
      fetchDayOffDetailsById: 'dayoffs/getDayOffDetailsById',
      getStaffListBySalonId: 'salonsStaff/getStaffListBySalonId',
      createDayOff: 'dayoffs/createDayOff',
      updateDayOff: 'dayoffs/updateDayOff',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    ...mapMutations({
      setCreatedDayoff: 'calendar/SET_CREATED_DAYOFF',
      setEditedDayoff: 'calendar/SET_EDITED_DAYOFF',
      selectedDayOffId: 'calendar/SET_SELECTED_DAYSOFF_ID',
      removeDayOffById: 'calendar/REMOVE_DAYOFF_BY_ID'
    }),
    setDayoffDataFromCalendar() {
      if (!this.getChoosenEmptyDay) return
      this.formFields.date = moment(this.getChoosenEmptyDay.date).format('MMM D, YYYY')
      if (this.getChoosenEmptyDay.start_time) {
        this.formFields.start_hours = this.calcHours(this.getChoosenEmptyDay.start_time)
        this.formFields.start_minutes = this.calcMinutes(this.getChoosenEmptyDay.start_time)
        this.activatedFullDay = false
      }
      this.staffList.forEach(el => {
        if (
          this.getChoosenEmptyDay.staff.staff_id &&
          el.staff_id &&
          this.getChoosenEmptyDay.staff.staff_id === el.staff_id
        ) {
          this.formFields.staff = el
        } else if (
          this.getChoosenEmptyDay.staff.manager_id &&
          el.manager_id &&
          this.getChoosenEmptyDay.staff.manager_id === el.manager_id
        ) {
          this.formFields.staff = el
        }
      })
    },
    submitForm() {
      this.submitProcess = true
      this.$validator.validateAll().then(result => {
        if (result) {
          let payload = {
            ...{ title: this.formFields.title },
            ...{ description: this.formFields.description },
            ...{ date: moment(this.formFields.date).format('YYYY-MM-DD') },
            ...{ start_time: null },
            ...{ end_time: null }
          }

          if (!this.activatedFullDay) {
            payload = {
              ...payload,
              ...{
                start_time: this.calcFullTime(this.formFields.start_hours, this.formFields.start_minutes)
              },
              ...{
                end_time: this.calcFullTime(this.formFields.end_hours, this.formFields.end_minutes)
              }
            }
          }

          if (this.formFields.staff && this.formFields.staff.id !== 0) {
            if (this.formFields.staff.staff_id) {
              payload = {
                ...payload,
                staff_id: this.formFields.staff.staff_id
              }
            } else if (this.formFields.staff.manager_id) {
              payload = {
                ...payload,
                manager_id: this.formFields.staff.manager_id
              }
            }
          } else {
            payload = {
              ...payload,
              ...{ salon_id: this.accountInfo.salon_id }
            }
          }

          if (!this.weekendId) {
            this.createDayOff(payload).then(data => {
              this.setSuccess(this.createSuccessMessage)
              this.submitProcess = false
              this.setCreatedDayoff(data)
              this.$bvModal.hide('dayoff-modal')
            })
          } else {
            payload = { ...payload, ...{ dayoff_id: this.weekendId } }

            this.updateDayOff(payload).then(data => {
              this.setSuccess(this.updateSuccessMessage)
              this.submitProcess = false
              this.setEditedDayoff(data)
              this.$bvModal.hide('dayoff-modal')
            })
          }
        } else {
          this.submitProcess = false
        }
      })
    },
    calcFullTime(hours, minutes) {
      if (hours === 0) {
        hours = '00'
      }

      return `${hours}:${minutes}`
    },
    calcMeridiem(time) {
      let hours = Number(time.split(':')[0])

      if (hours >= 12) {
        return 'PM'
      }

      return 'AM'
    },
    calcHours(time) {
      return Number(time.split(':')[0])
    },
    calcMinutes(time) {
      let minutes = Number(time.split(':')[1])

      if (minutes === 0) {
        minutes = '00'
      }

      return minutes
    },
    calculateStartMinutes(startHour, lastHourStart, lastHourEnd, firstHourEnd, minMinuteStart, maxMinuteEnd) {
      let minutes = []

      if (startHour === lastHourStart && startHour === lastHourEnd) {
        minutes = this.minutesList.filter(minute => Number(minute) <= maxMinuteEnd)
      } else if (startHour === firstHourEnd && startHour) {
        minutes = this.minutesList.filter(minute => Number(minute) >= minMinuteStart)
      } else if (startHour === lastHourEnd && startHour) {
        minutes = this.minutesList.filter(minute => Number(minute) <= maxMinuteEnd)
      } else {
        minutes = this.minutesList
      }

      return minutes
    },
    calculateEndMinutes(endHour, startHour, lastHourEnd, startMinute, maxMinuteEnd) {
      let minutes = []

      if (endHour === lastHourEnd) {
        minutes = this.minutesList.filter(minute => {
          if (startHour === lastHourEnd) {
            return Number(minute) >= startMinute && Number(minute) <= maxMinuteEnd
          } else {
            return Number(minute) <= maxMinuteEnd
          }
        })
      } else if (endHour === startHour) {
        minutes = this.minutesList.filter(minute => Number(minute) > startMinute)
      } else {
        minutes = this.minutesList
      }

      return minutes
    },
    confirmMessage() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [this.$t('common.confirm.delete_title')])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light',
          cancelTitle: `${this.$t('alert_message.cancel_title')}`
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    },
    deleteProcess() {
      this.deleteSingleDayOffById(this.weekendId).then(response => {
        this.removeDayOffById(this.weekendId)
        this.setSuccess(this.deletedSuccessMessage)
        this.close()
      })
    },
    deleteWeekend() {
      this.confirmMessage()
        .then(value => {
          if (value) {
            this.deleteProcess()
          }
        })
        .catch(error => {})
    },
    close() {
      this.$bvModal.hide('dayoff-modal')
    }
  },
  beforeDestroy() {
    this.selectedDayOffId(null)
  }
}
</script>
