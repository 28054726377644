<template>
  <vc-date-picker
    ref="datepicker"
    :value="toDate"
    :locale="siteLang === 'null' || !siteLang ? 'en' : siteLang"
    class="date-picker"
    :popover="{ placement: 'bottom', visibility: 'click' }"
    color="orange"
    :rows="1"
    :nav-visibility="'hidden'"
    is-expanded
    @popoverWillShow="popoverWillShow"
    @popoverWillHide="popoverWillHide"
    @input="input"
  >
    <a slot="header-left-button">
      <font-awesome-icon :icon="['far', 'angle-left']" class="text-gray" style="font-size: 18px;"></font-awesome-icon>
    </a>
    <a slot="header-right-button">
      <font-awesome-icon :icon="['far', 'angle-right']" class="text-gray" style="font-size: 18px;"></font-awesome-icon>
    </a>

    <button class="ml-3 d-flex align-items-center text-gray">
      <span class="date-info mr-2" v-html="title"></span>
      <font-awesome-icon v-if="isHidden" :icon="['far', 'angle-down']" style="font-size: 18px;"></font-awesome-icon>
      <font-awesome-icon v-else :icon="['far', 'angle-up']" style="font-size: 18px;"></font-awesome-icon>
    </button>
  </vc-date-picker>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: moment().format('YYYY-MM-DD')
    }
  },
  data() {
    return {
      toDate: new Date(),
      isHidden: true
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    })
  },
  watch: {
    date(newVal) {
      this.toDate = new Date(this.date)
    }
  },
  mounted() {
    this.toDate = new Date(this.date)
  },
  methods: {
    popoverWillShow() {
      this.isHidden = false
    },
    popoverWillHide() {
      this.isHidden = true
    },
    input(val) {
      if (val && this.toDate !== val) {
        this.toDate = val

        this.$router.push({
          name: this.$route.name,
          params: { type: this.$route.params.type, date: moment(val).format('YYYY-MM-DD') }
        })
      }
    }
  }
}
</script>

<style lang="scss"></style>
