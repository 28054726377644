<template>
  <b-modal
    id="add-client-modal"
    header-class="modal-header p-0 border-bottom-0"
    body-class="filterModalBody px-1 px-md-2 pb-0"
    footer-class="pt-4 px-0 d-block"
    centered
    @hide="onCloseCreateClientWindow"
  >
    <template v-slot:modal-header="{ close }">
      <div class="top-block w-100">
        <div class="d-flex pt-1-5 justify-content-between">
          <div class="w-100 d-flex justify-content-around"></div>
          <font-awesome-icon
            :icon="['far', 'times']"
            class="icon-close-mobile text-gray mr-1-5"
            @click="close()"
          ></font-awesome-icon>
        </div>
      </div>
    </template>
    <form class="px-1-5 px-md-2">
      <div class="row">
        <div class="col-12 d-flex justify-content-center mb-2">
          <h4>
            {{ $t('register-client.title') }}
          </h4>
        </div>
        <div class="col-12 px-0 d-flex justify-content-center">
          <div class="checkbox mr-1-5 mb-2 ml-1">
            <label class="mb-0 pl-2 pt-0-3">
              <input v-model="formFields.register" type="checkbox" class="activateWeekend" />
              <i class="input-helper"></i>
            </label>
          </div>
          <h6>
            {{ $t('register-client.register-first') }} (<span class="h6 text-primary">{{
              $t('register-client.register-last')
            }}</span
            >)
          </h6>
        </div>
        <div class="col-12">
          <div class="row mb-1-5">
            <div class="col-6">
              <label for="title" class="poppins-font text-black mb-0-5 font-weight-medium">
                {{ $t(formFieldsI18KeyLabel.firstName) }}
              </label>
              <div class="input-group col-12 px-0">
                <input
                  :id="'first-name'"
                  v-model="formFields.firstName"
                  v-validate="fieldValidationRule + '|max:100'"
                  :disabled="!formFields.register"
                  :data-vv-as="$t('register-client.validation-errors.first-name')"
                  type="text"
                  class="calendar-inputs-height form-control w-100 px-1-5"
                  :name="'first-name'"
                  :placeholder="`${$t('register-client.fields.title.first-name')}`"
                />
              </div>
              <span v-if="errors.has('first-name')" class="error-message mt-0-5">{{ errors.first('first-name') }}</span>
            </div>
            <div class="col-6">
              <label for="title" class="poppins-font text-black mb-0-5 font-weight-medium">
                {{ $t(formFieldsI18KeyLabel.lastName) }}
              </label>
              <div class="input-group col-12 px-0">
                <input
                  :id="'last-name'"
                  v-model="formFields.lastName"
                  v-validate="fieldValidationRule + '|max:100'"
                  :disabled="!formFields.register"
                  :data-vv-as="$t('register-client.validation-errors.last-name')"
                  type="text"
                  class="calendar-inputs-height form-control w-100 px-1-5"
                  :name="'last-name'"
                  :placeholder="`${$t('register-client.fields.title.last-name')}`"
                />
              </div>
              <span v-if="errors.has('last-name')" class="error-message mt-0-5">{{ errors.first('last-name') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="title" class="poppins-font text-black mb-0-5 font-weight-medium">
                {{ $t(formFieldsI18KeyLabel.phone) }}
              </label>
              <div class="input-group col-12 px-0">
                <input
                  :id="'phone'"
                  v-model="formFields.phone"
                  v-validate="'|max:13|numeric'"
                  :disabled="!formFields.register"
                  :data-vv-as="$t('register-client.validation-errors.phone')"
                  type="text"
                  class="calendar-inputs-height form-control w-100 px-1-5"
                  :name="'phone'"
                  :placeholder="`${$t('register-client.fields.title.phone')}`"
                />
              </div>
              <span v-if="errors.has('phone')" class="error-message mt-0-5">{{ errors.first('phone') }}</span>
            </div>
            <div class="col-6">
              <label for="title" class="poppins-font text-black mb-0-5 font-weight-medium">
                {{ $t(formFieldsI18KeyLabel.email) }}
              </label>
              <div class="input-group col-12 px-0">
                <input
                  :id="'email'"
                  v-model="formFields.email"
                  v-validate="fieldValidationRule + '|max:40|email'"
                  :disabled="!formFields.register"
                  :data-vv-as="$t('register-client.validation-errors.email')"
                  type="text"
                  class="calendar-inputs-height form-control w-100 px-1-5"
                  :name="'email'"
                  :placeholder="`${$t('register-client.fields.title.email')}`"
                />
              </div>
              <span v-if="errors.has('email')" class="error-message mt-0-5">{{ errors.first('email') }}</span>
            </div>
            <div class="col-12 mt-1-5">
              <label class="poppins-font text-black mb-0-5 font-weight-medium">
                {{ $t(formFieldsI18KeyLabel.note) }}
              </label>
              <div class="input-group">
                <textarea
                  v-model="formFields.note"
                  v-validate="'max:255'"
                  :data-vv-as="$t('register-client.validation-errors.note')"
                  name="description"
                  class="form-control calendarDayoffModalTextArea"
                  rows="10"
                ></textarea>
              </div>
              <span v-if="errors.has('description')" class="error-message mt-0-5 w-100">
                {{ errors.first('description') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <template v-slot:modal-footer="{ close }">
      <div class="row px-1 px-md-4 mb-3 mt-0 justify-content-center">
        <div class="col-auto col-md-4">
          <button class="btn btn-outline-secondary-custom btn-block collapsed py-0-8" @click="back">
            <span class="fz14">
              {{ $t('register-client.back') }}
            </span>
          </button>
        </div>
        <div class="col">
          <button class="btn btn-block btn-primary py-0-8" @click="submitForm">
            <span class="text-nowrap fz14">
              {{ $t('register-client.next') }}
              <font-awesome-icon
                :icon="['fal', 'long-arrow-right']"
                class="text-white ml-1 align-middle"
                style="font-size:18px;"
              ></font-awesome-icon>
            </span>
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      fieldValidationRule: 'required',
      dataIsFilled: false,
      formFieldsI18KeyLabel: {
        firstName: 'register-client.fields.title.first-name',
        lastName: 'register-client.fields.title.last-name',
        phone: 'register-client.fields.title.phone',
        email: 'register-client.fields.title.email',
        note: 'register-client.fields.title.note'
      },
      formFields: {
        register: true,
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        note: ''
      }
    }
  },
  watch: {
    'formFields.register': {
      deep: true,
      handler(newVal) {
        if (!newVal) this.createWithOutRegister()
      }
    }
  },
  computed: {
    ...mapGetters({
      getUsers: 'user/getUsersByMail'
    })
  },
  methods: {
    ...mapActions({
      fetchSearchUsersByEmails: 'user/searchUsersByEmails',
      setError: 'userMessages/setError'
    }),
    onCloseCreateClientWindow() {
      if (!this.dataIsFilled) {
        this.resetData()
        this.$emit('set-register-client-info', null)
      }
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.formFields.register) {
            this.fetchSearchUsersByEmails(this.formFields.email)
              .then(data => {
                if (!this.getUsers.length) {
                  this.$emit('set-register-client-info', this.formFields)
                  this.dataIsFilled = true
                  this.$bvModal.hide('add-client-modal')
                  this.$bvModal.show('booking-create')
                } else {
                  this.$validator.errors.add({ field: 'email', msg: this.$t('common.error.email_taken') })
                }
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            this.$emit('set-register-client-info', this.formFields)
            this.dataIsFilled = true
            this.$bvModal.hide('add-client-modal')
            this.$bvModal.show('booking-create')
          }
        } else this.dataIsFilled = false
      })
    },
    createWithOutRegister() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [this.$t('booking.confirm.title')]),
        h('p', { class: ['text-center text-small text-black pb-0 mb-0 lh-1-5'] }, [this.$t('booking.confirm.subtitle')])
      ])

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light'
        })
        .then(value => {
          if (value) {
            this.transferClientFieldsToNote()
            this.errors.clear()
            this.formFields.register = false
          } else {
            this.formFields.register = true
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    transferClientFieldsToNote() {
      const { firstName, lastName, phone, email } = this.formFields

      const formFields = {
        firstName,
        lastName,
        phone,
        email
      }

      let note = ''

      const labelText = key => this.$t(this.formFieldsI18KeyLabel[key])

      for (let key in formFields) {
        let lineBreack = key !== 'email' ? '\n' : ''
        let label = `${labelText(key)}`
        let value = formFields[key]

        note = note + `${label}: ${value}${lineBreack}`
      }

      this.formFields.note = note
    },
    resetData() {
      this.formFields = {
        register: true,
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        note: ''
      }
    },
    back() {
      this.dataIsFilled = true
      this.$bvModal.hide('add-client-modal')
      this.$bvModal.show('client-info-modal')
    }
  }
}
</script>
