<template>
  <VApp id="main-calendar" :class="'calendar-container navbar-offset-tablet h-auto'">
    <div
      class="px-1-5 pt-1 salon-flow"
      :style="type === 'custom-daily' ? 'overflow-y: hidden !important' : ''"
      :class="{ 'custom-daily': type === 'custom-daily' }"
    >
      <div class="row fill-height">
        <div class="col">
          <!-- CalendarNav -->
          <CalendarNav
            :template-type="'salon'"
            :type="type"
            :staff-list="staffList"
            :title="title"
            :selected-staff="selectedStaff"
            :date="today"
            @prev="prev"
            @next="next"
            @set-type="setType"
          />

          <div class="scrollbar" @scroll="scrollFakeBar($event)">
            <div class="child"></div>
          </div>

          <div
            class="w-100 position-relative calendar-root calandar-container"
            :class="`${type}`"
            @scroll="scrollCalendar($event)"
          >
            <VSheet class="d-inline-block" :class="type === 'week' ? 'd-flex' : 'bottom-row'">
              <HeaderRowStaff v-if="type === 'custom-daily'" :staff-list="staffList" />
              <BodyColumnsStaff v-else :staff-list="staffList" />

              <VCalendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :end="endDayForCustomDaily"
                :max-days="staffList.length ? staffList.length : 1"
                :type="type"
                :events="events"
                :start="today"
                :short-weekdays="false"
                :event-height="0"
                :show-interval-label="showIntervalLabel"
                :hide-header="type === 'custom-daily'"
                :first-interval="type === 'custom-daily' ? firstInterval : 0"
                :interval-count="type !== 'custom-daily' ? staffList.length : countInterval"
                :interval-format="intervalFormat"
                :interval-minutes="type === 'custom-daily' ? 15 : 60"
                :interval-height="type === 'custom-daily' ? 32 : 80"
                :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                :locale="$root.$i18n.locale"
                :class="`col-12 pr-0 border-left-0 calandar-block ${type}`"
                :event-start="'start_time'"
                :event-end="'end_time'"
                :event-overlap-mode="'column'"
                @click:event="showEvent"
                @change="updateRange"
              >
                <template slot="interval" slot-scope="day">
                  <button
                    class="free-time-block position-absolute w-100 h-100"
                    @click="toggleMenu($event, day)"
                  ></button>
                </template>

                <template #day-body="{ date, week }">
                  <div
                    v-if="today === now"
                    class="v-current-time"
                    :class="{ first: date === week[0].date }"
                    :style="{ top: nowY }"
                  >
                    <span v-if="cal" class="current-time-value text-primary font-weight-semibold">
                      {{ cal.times.now.time }}
                    </span>
                  </div>
                </template>

                <template slot="day-header" slot-scope="{ date }">
                  <div class="v-calendar-daily_head-day-label d-block">
                    <span class="day-number text-center">{{ date | headerDateDayFormat }}</span>
                  </div>
                </template>

                <template slot="event" slot-scope="{ event }">
                  <div
                    :class="[
                      type === 'custom-daily' ? (event.isWeekend ? 'weekend' : 'event') : '',
                      event.allSalonEvent ? 'allSalonEvent' : ''
                    ]"
                    class="h-100"
                  >
                    <!-- EVENT COMPONENT -->
                    <Event
                      v-if="event.id && type === 'custom-daily'"
                      :title="event.name"
                      :event="event"
                      @preview-boooking-by-id="previewBoookingById"
                    />

                    <!-- EVENT COUNT COMPONENT -->
                    <EventCount
                      v-if="
                        (event.type === 'booking' && type === 'week') ||
                          (event.type === 'dayoff' && type === 'week' && !event.isWeekend)
                      "
                      :event="event"
                    />

                    <!-- EVENT DAY OFF COMPONENT -->
                    <EventWeekend v-if="event.isWeekend" />
                  </div>
                </template>
              </VCalendar>
            </VSheet>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="dayoff-modal"
      ref="dayoffModal"
      body-class="filterModalBody px-1-5 px-sm-4 pb-4"
      footer-class="pt-2 px-0 d-block"
      hide-header="true"
      hide-footer="true"
      centered
    >
      <CreateDayoffForm ref="dayoffForm" :calendar-type="type" />
    </b-modal>

    <b-modal
      id="booking-view-modal"
      ref="bookingViewModal"
      header-class="modal-header p-0 border-bottom-0"
      body-class="filterModalBody pb-1 px-0"
      hide-footer
      size="lg"
      centered
    >
      <template v-slot:modal-header="{ close }">
        <div class="top-block w-100">
          <div class="d-flex pt-1-5 justify-content-between">
            <div class="w-100 d-flex justify-content-around"></div>
            <font-awesome-icon
              :icon="['far', 'times']"
              class="icon-close-mobile text-gray mr-1-5"
              @click="close()"
            ></font-awesome-icon>
          </div>
        </div>
      </template>

      <div class="col px-0">
        <BookingPreview :booking-id="previewBookingId" />
      </div>
    </b-modal>

    <ClientAndBookingCreateSection />

    <VMenu
      v-model="showMenu"
      top
      :position-x="x"
      :position-y="y"
      offset-y
      style="max-width: 160px"
      :close-on-click="false"
    >
      <b-button variant="link" class="close-menu-icon bg-white p-0 border-0" @click="showMenu = false">
        <font-awesome-icon :icon="['fas', 'times-circle']" class="text-gray" style="font-size:24px"></font-awesome-icon>
      </b-button>
      <div class="d-flex flex-column btns-block">
        <b-button variant="black" class="text-left text-small py-1-1" @click="viewDay()">
          <font-awesome-icon :icon="['fas', 'calendar-day']" class="text-white mr-2"> </font-awesome-icon>
          {{ $t('salon-calendar.interval_menu.day_view') }}
        </b-button>
        <b-button
          v-if="showCreateDaysOffBtn()"
          variant="black"
          class="mt-0-5 text-left  text-small py-1-1"
          @click="showDayOffModal()"
        >
          <font-awesome-icon :icon="['fas', 'ban']" class="text-white mr-2"> </font-awesome-icon>
          {{ $t('salon-calendar.interval_menu.block_time_slot') }}
        </b-button>
        <b-button
          v-if="showCreateBookingBtn()"
          variant="primary"
          class="mt-0-5 text-left text-small h-auto py-1-1"
          @click="showCreateBookingModal()"
        >
          <font-awesome-icon :icon="['fas', 'user-circle']" class="text-white mr-2"> </font-awesome-icon>
          {{ $t('salon-calendar.interval_menu.create_new_booking') }}
        </b-button>
      </div>
    </VMenu>
  </VApp>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import moment from 'moment'
import 'vue-select/dist/vue-select.css'
import { VSheet, VCalendar, VMenu, VApp } from 'vuetify/lib'
import { eventHub } from '@/_utils/eventhub'

import CalendarNav from './components/CalendarNav.vue'
import HeaderRowStaff from './components/HeaderRowStaff.vue'
import BodyColumnsStaff from './components/BodyColumnsStaff.vue'
import EventWeekend from './components/EventWeekend.vue'
import Event from './components/Event.vue'
import EventCount from './components/EventCount.vue'

import ClientAndBookingCreateSection from './components/ClientAndBookingCreateSection'
import CalendarMixin from './mixins/Calendar.vue'
import CreateDayoffForm from './components/CreateDayoffForm.vue'
import BookingPreview from './components/BookingPreview.vue'

export default {
  components: {
    VSheet,
    VCalendar,
    VMenu,
    VApp,

    CalendarNav,
    HeaderRowStaff,
    BodyColumnsStaff,
    EventWeekend,
    Event,
    EventCount,
    CreateDayoffForm,
    BookingPreview,
    ClientAndBookingCreateSection
  },
  mixins: [CalendarMixin],
  data: () => ({
    type: 'custom-daily',
    choosenTimeData: {}
  }),
  computed: {
    ...mapGetters({
      eventsSalonDayByParams: 'calendar/getEventsSalonDayByParams',
      accountInfo: 'user/getAccountInfo',
      staffList: 'salonsStaff/getStaffList',
      workDays: 'workDays/getWorkDays',
      userRole: 'user/getRole',
      getStaffServices: 'salonsStaff/getStaffServices'
    }),
    events() {
      return this.eventsSalonDayByParams(
        this.staffList,
        this.type === 'custom-daily' ? this.today : this.$route.params.date,
        this.type
      )
    },
    endDayForCustomDaily() {
      if (this.start) {
        return moment(this.start.date)
          .add(this.staffList.length, 'days')
          .format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCalendarBookingsBySalonAndPeriod: 'calendar/getCalendarBookingsBySalonAndPeriod',
      fetchCalendarDayOffsBySalonAndPeriod: 'calendar/getCalendarDayOffsBySalonAndPeriod',
      fetchStaffListBySalonId: 'salonsStaff/getStaffListBySalonId',
      fetchWorkDays: 'workDays/getWorkDays',
      fetchStaffProfileInfo: 'salonsStaff/getStaffServicesInfo',
      setError: 'userMessages/setError'
    }),
    ...mapMutations({
      setSelectedStaff: 'calendar/SET_SELECTED_STAFF',
      setChoosenDayData: 'calendar/SET_CHOOSEN_DAY_DATA',
      clearChoosenDayData: 'calendar/CLEAR_CHOOSEN_DAY_DATA',
      setChoosenTypeAndTime: 'calendar/SET_CALENDAR_CHOOSEN_TIME'
    }),
    setType(type, newDate) {
      if (this.type === type) {
        return
      }

      let periodType = type === 'custom-daily' ? 'day' : type

      let now = moment()
      let monday = now.clone().weekday(1)
      let date = monday.format('YYYY-MM-DD')

      if (type !== 'week') {
        date = newDate ? newDate : now.format('YYYY-MM-DD')
      }

      if (this.$route.path !== `/calendar/${periodType}/${date}` && this.$route.name === 'calendar-salon') {
        this.$router.push(`/calendar/${periodType}/${date}`)
      }

      // setTimeout(this.createScrolbarChildNode, 500)
    },
    async fetchCalendarData(period, date) {
      eventHub.$emit('before-request')

      let periodType = period === 'custom-daily' ? 'day' : period

      await Promise.all([
        this.fetchWorkDays(this.accountInfo.salon_id).then(() => {
          if (this.type === 'custom-daily') {
            this.calcOpenAndClosedTImeForDay(
              moment(date)
                .locale('En')
                .format('dddd')
            )
          }
        }),
        this.fetchStaffListBySalonId(this.accountInfo.salon_id)
      ])

      const params = {
        salon_id: this.accountInfo.salon_id,
        day: date,
        period: periodType
      }

      this.setSalonWeekendsData(this.type)

      await Promise.all([
        this.fetchCalendarBookingsBySalonAndPeriod(params),
        this.fetchCalendarDayOffsBySalonAndPeriod(params)
      ])

      eventHub.$emit('after-response-without-timer')

      return true
    },
    setSalonWeekendsData(type) {
      this.choosenTimeData = {}
      if (type === 'custom-daily') {
        this.choosenTimeData.type = type
        this.choosenTimeData.date = this.today
        this.setChoosenTypeAndTime(this.choosenTimeData)
      } else {
        this.choosenTimeData.type = type
        this.choosenTimeData.start = this.start.date
        this.choosenTimeData.end = this.end.date
        this.choosenTimeData.past = this.start.past
        this.setChoosenTypeAndTime(this.choosenTimeData)
      }
    },
    showCreateBookingModal() {
      if (!this.actionAccessRoles.includes(this.userRole)) return false
      const { date, time, week, minute } = this.selectedInterval

      const staffIndex =
        this.type === 'custom-daily' ? week.findIndex(day => day.date === date) : Number(time.substr(0, 2))

      const minutes = parseInt(minute / 15) * 15 || '00'
      const staff = this.staffList[staffIndex]
      const checkFullDate = this.type === 'custom-daily'
      const eventDate = checkFullDate ? `${this.today} ${time}` : date

      this.emptyDateChoosenData.staff = staff
      this.emptyDateChoosenData.date = moment(eventDate).format('YYYY-MM-DD')

      if (this.type === 'custom-daily') this.emptyDateChoosenData.start_time = `${this.calcHours(time)}:${minutes}`
      else this.emptyDateChoosenData.start_time = null

      this.setChoosenDayData(this.emptyDateChoosenData)
      this.$bvModal.show('client-info-modal')
    },
    showCreateBookingBtn() {
      if (!this.actionAccessRoles.includes(this.userRole)) return false
      if (!this.selectedInterval) return false

      const { date, time, week } = this.selectedInterval

      const staffIndex =
        this.type === 'custom-daily' ? week.findIndex(day => day.date === date) : Number(time.substr(0, 2))

      return this.showCreateDaysOffBtn() && this.staffList[staffIndex].staff_id
    },
    showCreateDaysOffBtn() {
      if (!this.actionAccessRoles.includes(this.userRole)) return false
      if (!this.selectedInterval) return false

      const { date, time } = this.selectedInterval

      const checkFullDate = this.type === 'custom-daily'
      const eventDate = checkFullDate ? `${this.today} ${time}` : date

      let nowDate = moment()

      if (checkFullDate) {
        nowDate = nowDate.format('YYYY-MM-DD HH:mm')
      } else {
        nowDate = nowDate.format('YYYY-MM-DD')
      }

      return moment(eventDate).diff(nowDate, 'minutes') > 0
    },
    showDayOffModal() {
      if (!this.actionAccessRoles.includes(this.userRole)) return false
      if (!this.selectedInterval) return false
      const { date, time, week, minute } = this.selectedInterval

      const staffIndex =
        this.type === 'custom-daily' ? week.findIndex(day => day.date === date) : Number(time.substr(0, 2))

      const staff = this.staffList[staffIndex]
      const minutes = parseInt(minute / 15) * 15 || '00'

      const checkFullDate = this.type === 'custom-daily'
      const eventDate = checkFullDate ? `${this.today} ${time}` : date

      this.emptyDateChoosenData.staff = staff
      this.emptyDateChoosenData.date = moment(eventDate).format('YYYY-MM-DD')

      if (this.type === 'custom-daily') this.emptyDateChoosenData.start_time = `${this.calcHours(time)}:${minutes}`
      else this.emptyDateChoosenData.start_time = null

      this.setChoosenDayData(this.emptyDateChoosenData)
      this.$bvModal.show('dayoff-modal')
    },
    viewDay() {
      if (!this.selectedInterval) return false

      const { date, time, week } = this.selectedInterval

      const checkFullDate = this.type === 'custom-daily'
      const eventDate = checkFullDate ? this.today : date

      const staffIndex =
        this.type === 'custom-daily' ? week.findIndex(day => day.date === date) : Number(time.substr(0, 2))

      this.setSelectedStaff(this.staffList[staffIndex])

      this.$router.push({
        name: 'calendar-staff',
        params: { type: 'day', date: eventDate }
      })
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (from.params.type === 'week' && to.params.type === 'day') {
      // this.setType('custom-daily', to.params.date)
    }

    next()
  }
}
</script>

<style lang="scss"></style>
