import moment from 'moment/src/moment'

export default {
    methods: {
        validateParams(type, date) {
            return this.validateDateParams(date) && this.validateTypeParams(type)
        },
        validateDateParams(date) {
            return moment(date, 'YYYY-MM-DD', true).isValid()
        },
        validateTypeParams(type) {
            return ['day', 'week'].includes(type)
        },
    }
}