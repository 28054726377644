<script>
import moment from 'moment'
import { mapMutations, mapGetters } from 'vuex'
import validateCalendarParams from '@/_mixins/validateCalendarParams.js'
import isMobile from '@/_mixins/isMobile.js'

export default {
  mixins: [validateCalendarParams, isMobile],
  data: () => ({
    now: moment().format('YYYY-MM-DD'),
    actionAccessRoles: ['owner', 'manager', 'salon_manager', 'admin'],
    ready: false,
    focus: '',
    today: moment().format('YYYY-MM-DD'),
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    firstInterval: 0,
    countInterval: 0,
    isReceptionist: false,
    emptyDateChoosenData: {
      staff: null,
      date: null
    },
    showMenu: false,
    x: 0,
    y: 0,
    selectedInterval: null,
    previewBookingId: null
  }),
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang',
      storePreviewBookingId: 'calendar/getPreviewBookingId',
      getCalendarScrollWidth: 'calendar/getCalendarScrollWidth'
    }),
    title() {
      const { start, end } = this

      if (!start || !end) {
        return ''
      }

      moment.locale(this.siteLang)
      let startDate = moment(start.date)

      const startYear = startDate.format('YYYY')
      const startMonth = startDate.format('MMM')
      const startDayName = startDate.format('dddd')
      const startDay = startDate.format('DD')

      switch (this.type) {
        case 'week':
          return `<span class="text-black">${start.day} - ${end.day},</span><span class="text-secondary font-weight-normal ml-1">${startMonth} ${startYear}</span>`
        case 'day':
          return `<span class="text-black">${startDayName} ${startDay},</span><span class="text-secondary font-weight-normal ml-1">${startMonth} ${startYear}</span>`
        case 'custom-daily':
          return `<span class="text-black">${startDayName} ${startDay},</span><span class="text-secondary font-weight-normal ml-1">${startMonth} ${startYear}</span>`
      }
      return ''
    },
    cal() {
      return this.ready ? this.$refs.calendar : null
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 16 + 'px' : '-10px'
    }
  },
  watch: {
    events: {
      deep: true,
      handler(newVal) {
        this.parrentEventWidth()
      }
    },
    $route(to, from) {
      const routeNames = ['calendar-salon', 'calendar-staff']

      this.selectedInterval = null

      if (routeNames.includes(to.name)) {
        if (this.validateParams(to.params.type, to.params.date)) {
          this.type =
            to.params.type === 'day' && this.$route.name === 'calendar-salon' ? 'custom-daily' : to.params.type

          if (this.start.date) {
            this.start.date = to.params.date
          }

          this.today = to.params.date
          this.ready = true
          this.setToday()

          this.fetchCalendarData(to.params.type, to.params.date).then(() => this.createScrolbarChildNode())
        } else {
          this.setError({
            type: 'error',
            message: this.$t(`common.error.wrong_url_params`)
          })

          this.$router.push('/salon')
        }
      }
    }
  },
  mounted() {
    this.clearStateBookking()
    this.clearStateDaysOff()

    window.addEventListener('resize', this.resizeHandler)

    this.ready = true

    document.addEventListener('click', this.onClick)

    if (this.storePreviewBookingId) {
      this.previewBoookingById(this.storePreviewBookingId)
    }

    if (!this.validateParams(this.$route.params.type, this.$route.params.date)) {
      this.setError({
        type: 'error',
        message: this.$t(`common.error.wrong_url_params`)
      })

      this.$router.push('/salon')
    } else {
      this.type =
        this.$route.params.type === 'day' && this.$route.name === 'calendar-salon'
          ? 'custom-daily'
          : this.$route.params.type

      if (this.start.date) {
        this.start.date = this.$route.params.date
      }

      this.today = this.$route.params.date
      this.ready = true

      this.scrollToTime()
      this.updateTime()

      if (this.accountInfo.salon_id) {
        this.fetchCalendarData(this.type, this.start.date)
          .then(result => {
            const allSalonDaysOffWidthPercentage = this.staffList.length * 100
            this.$root.$el.style.setProperty('--all-salon-event-width', `${allSalonDaysOffWidthPercentage}%`)
            this.createScrolbarChildNode()
          })
          .catch(err => {})
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClick)
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    ...mapMutations({
      clearStateBookking: 'calendar/CLEAR_CALENDAR_BOOKING',
      clearStateDaysOff: 'calendar/CLEAR_CALENDAR_DAYSOFF',
      setCalendarScrollWidth: 'calendar/SET_CALENDAR_SCROLL_WIDTH'
    }),
    resizeHandler() {
      if (window.innerWidth <= 1200) {
        this.createScrolbarChildNode()
      }
    },
    createScrolbarChildNode() {
      let child = document.querySelector('.child')
      let rootCalendar = document.querySelector('.calendar-root')

      this.setTimeSidebarOffset(0)
      rootCalendar.scrollLeft = 0

      if (child && rootCalendar) {
        if (window.innerWidth > 1200 && this.type === 'week') {
          this.setCalendarScrollWidth(0)
          child.style.width = 0
        } else {
          this.setCalendarScrollWidth(rootCalendar.scrollWidth)
          child.style.width = this.getCalendarScrollWidth + 'px'
        }
      }
    },
    scrollFakeBar(event) {
      if (this.isMobile()) return
      let rootCalendar = document.querySelector('.calendar-root')

      this.setTimeSidebarOffset(event.target.scrollLeft)
      rootCalendar.scrollLeft = event.target.scrollLeft
    },
    scrollCalendar(event) {
      let scrollbar = document.querySelector('.scrollbar')

      this.setTimeSidebarOffset(event.target.scrollLeft)
      scrollbar.scrollLeft = event.target.scrollLeft
    },
    setTimeSidebarOffset(leftOffset) {
      let sidebar = document.querySelector('.v-calendar-daily__intervals-body')

      sidebar.style.left = `${leftOffset}px`
    },
    previewBoookingById(id) {
      this.previewBookingId = id
      this.$bvModal.show('booking-view-modal')
    },
    parrentEventWidth() {
      let allParrentAllalonEvents = document.getElementsByClassName('parrent_allSalonEvent')

      for (let index = 0; index < allParrentAllalonEvents.length; index++) {
        let element = allParrentAllalonEvents[index]

        element.classList.remove('parrent_allSalonEvent')
      }

      setTimeout(() => {
        let allSalonEvents = document.getElementsByClassName('allSalonEvent')

        for (let index = 0; index < allSalonEvents.length; index++) {
          let element = allSalonEvents[index]
          if (element.offsetParent) {
            element.offsetParent.classList.add('parrent_allSalonEvent')
          }
        }
      }, 0)
    },
    onClick(e) {
      if (!e.target.classList.contains('free-time-block')) {
        this.showMenu = false
      }
    },
    intervalFormat(interval) {
      return interval.time
    },
    showIntervalLabel(interval) {
      return interval.minute === 0 || interval.minute
    },
    setToday() {
      this.focus = this.today
    },
    calcOpenAndClosedTImeForWeek() {
      this.firstInterval = Math.min.apply(
        null,
        this.workDays
          .filter(day => day.start_time !== null)
          .map(day => {
            return this.calcHours(day.start_time) * 4 + this.calcMinutes(day.start_time) / 15
          })
      )
      this.countInterval = Math.max.apply(
        null,
        this.workDays
          .filter(day => day.end_time !== null)
          .map(day => {
            return this.calcHours(day.end_time) * 4 + this.calcMinutes(day.end_time) / 15 - this.firstInterval + 1
          })
      )
    },
    calcOpenAndClosedTImeForDay(dayOfWeek) {
      const openTime = this.workDays.find(day => day.work_day.title === dayOfWeek).start_time
      const closedTime = this.workDays.find(day => day.work_day.title === dayOfWeek).end_time

      if (openTime !== undefined && openTime !== null && closedTime !== undefined && closedTime !== null) {
        this.firstInterval = this.calcHours(openTime) * 4 + this.calcMinutes(openTime) / 15
        this.countInterval = this.calcHours(closedTime) * 4 + this.calcMinutes(closedTime) / 15 - this.firstInterval + 1
      } else {
        this.firstInterval = 0
        this.countInterval = 96
      }
    },
    updateRange({ start, end }) {
      this.start = start
      this.end = end

      this.parrentEventWidth()
    },
    hasMinutes(time) {
      let minutes = Number(time.split(':')[1])
      return minutes !== 0
    },
    calcHours(time) {
      return Number(time.split(':')[0])
    },
    calcMinutes(time) {
      return Number(time.split(':')[1])
    },
    linkToBooking(event) {
      this.$router.push(`/booking/update/${event.id}`)
    },
    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime() {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
    async prev() {
      let periodType = this.type === 'custom-daily' ? 'day' : this.type

      if (this.type === 'custom-daily') {
        this.start.date = moment(this.start.date)
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
        this.today = this.start.date

        await this.$refs.calendar.prev()
      } else {
        await this.$refs.calendar.prev()
      }

      this.$router.push({
        name: this.$route.name,
        params: { type: periodType, date: this.start.date }
      })
    },
    async next() {
      let periodType = this.type === 'custom-daily' ? 'day' : this.type

      if (this.type === 'custom-daily') {
        this.start.date = moment(this.start.date)
          .add(1, 'days')
          .format('YYYY-MM-DD')
        this.today = this.start.date

        await this.$refs.calendar.next()
      } else {
        await this.$refs.calendar.next()
      }

      this.$router.push({
        name: this.$route.name,
        params: { type: periodType, date: this.start.date }
      })
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => (this.selectedOpen = true), 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    toggleMenu(e, intervalData) {
      e.preventDefault()

      this.showMenu = false

      this.selectedInterval = {
        ...intervalData
      }

      this.x = e.clientX
      this.y = e.clientY

      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    hideMenu() {
      this.showMenu = false
    }
  },
  filters: {
    headerDateDayFormat(val) {
      return moment(moment(val).toDate()).format('DD.MM')
    },
    startDateFormat(val) {
      return moment(val).format('HH:mm')
    },
    endDateFormat(val) {
      return moment(val).format('HH:mm')
    }
  }
}
</script>

<style lang="scss">
.parrent_allSalonEvent {
  width: var(--all-salon-event-width) !important;
}
</style>
