<template>
  <div class="col-auto p-0 calendar-staff-card">
    <div class="col-auto" style="min-height:65px;background-color: #f8f8f8;"></div>

    <div
      v-for="(item, key) in staffList"
      :key="`staff-${key}`"
      class="staff-block row mr-0"
      style="cursor:pointer"
      @click="redirectToStaffCalendar(item)"
    >
      <div class="col-auto d-none d-sm-block">
        <img v-if="item.avatar" :src="item.avatar.path" class="staff-avatar-small" alt />
        <img v-else src="@/assets/images/default/default_avatar.png" class="staff-avatar-small" alt />
      </div>
      <div class="col">
        <p class="mb-1 text-black poppins-font font-weight-medium text-truncate">
          {{ item.fullName }}
        </p>
        <span class="text-gray">
          {{ $t(`role.${item.role}`) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations } from 'vuex'

export default {
  name: 'BodyColumnsStaff',
  props: {
    staffList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...mapMutations({
      setSelectedStaff: 'calendar/SET_SELECTED_STAFF'
    }),
    redirectToStaffCalendar(staff, date = moment().format('YYYY-MM-DD')) {
      this.setSelectedStaff(staff)
      this.$router.push(`/calendar/staff/week/${moment(date).format('YYYY-MM-DD')}`)
    }
  }
}
</script>

<style lang="scss" scoped></style>
