<template>
  <main>
    <ClientInfo @clear-choosen-data="clearChoosenData" />
    <AddClient
      ref="addClientModal"
      :clear-add-client-data="clearUnderAddClientData"
      @set-register-client-info="setRegisterClientinfo"
    />
    <SearchClient ref="searchClientModal" @set-choosen-client-data="setChoosenClientData" />
    <BookingCreate :added-client="addClientFields" :selected-client="choosenClientFields" />
  </main>
</template>
<script>
import ClientInfo from './bookingModals/ClientInfo'
import AddClient from './bookingModals/AddClient'
import SearchClient from './bookingModals/SearchClient'
import BookingCreate from './bookingModals/BookingCreate'

export default {
  components: {
    ClientInfo,
    AddClient,
    SearchClient,
    BookingCreate
  },
  data() {
    return {
      addClientFields: null,
      choosenClientFields: null
    }
  },
  methods: {
    setRegisterClientinfo(val) {
      this.addClientFields = val
    },
    setChoosenClientData(val) {
      this.choosenClientFields = val
    },
    clearChoosenData(val) {
      if (val) {
        this.addClientFields = null
        this.choosenClientFields = null
        this.$refs.addClientModal.resetData()
        this.$refs.searchClientModal.resetData()
      }
    }
  }
}
</script>
