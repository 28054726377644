<template>
  <div height="64" class="top-row d-flex d-flex flex-column flex-sm-row  align-items-center mb-1-5 mb-md-1">
    <div class="nav-tabs d-flex border-0 mb-1 mb-md-0">
      <b-link
        class="btn btn-checkbox px-2 mr-2"
        :class="{ disabled: Date.parse(date) === Date.parse(now) }"
        @click.prevent="setToday()"
      >
        {{ $t('staff-calendar.today') }}
      </b-link>

      <b-button class="px-2 mr-2" variant="checkbox" @click="prevEmit()">
        <font-awesome-icon
          :icon="['fal', 'long-arrow-left']"
          class="align-middle"
          style="font-size:19px;"
        ></font-awesome-icon>
      </b-button>

      <b-button class="px-2" variant="checkbox" @click="nextEmit()">
        <font-awesome-icon
          :icon="['fal', 'long-arrow-right']"
          class="align-middle"
          style="font-size:19px;"
        ></font-awesome-icon>
      </b-button>

      <div class="d-block d-sm-none ml-4">
        <b-link
          :class="{ active: type === 'day' || type === 'custom-daily' }"
          class="btn btn-checkbox px-1-5 mx-1"
          @click.prevent="setTypeEmit('day')"
        >
          {{ $t('staff-calendar.day-button') }}
        </b-link>
        <b-link
          :class="{ active: type === 'week' }"
          class="btn btn-checkbox px-1-5"
          @click.prevent="setTypeEmit('week')"
        >
          {{ $t('staff-calendar.week-button') }}
        </b-link>
      </div>
    </div>

    <span class="mb-1 mb-md-0"><CalendarDatePicker :title="title" :date="date"/></span>

    <div class="nav-tabs d-flex border-0 mx-auto mr-sm-3 calendar-select-block mb-1 mb-md-0">
      <Multiselect
        v-if="templateType === 'staff'"
        v-model="selectedStaff"
        :options="staffListWithAllEmployee"
        label="label"
        :searchable="false"
        :select-label="''"
        :hide-selected="true"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc text-truncate">
            <img
              v-if="props.option.avatar"
              :src="props.option.avatar.path"
              class="avatar--small"
              alt="Avatar option.fullName"
            />
            <img
              v-else-if="props.option.staff_id || props.option.manager_id"
              src="@/assets/images/default/default_avatar.png"
              class="avatar--small"
              alt
            />

            <span class="option__title pl-2">{{ $t(props.option.fullName) }}</span>
          </span>
        </template>
        <template slot="caret" slot-scope="{ option, toggle }">
          <div
            class="multiselect__select d-flex align-items-center justify-content-center"
            @mousedown.prevent.stop="toggle()"
          >
            <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc text-truncate">
            <img
              v-if="props.option.avatar"
              :src="props.option.avatar.path"
              class="avatar--small"
              alt="Avatar option.fullName"
            />
            <img
              v-else-if="props.option.staff_id || props.option.manager_id"
              src="@/assets/images/default/default_avatar.png"
              class="avatar--small"
              alt
            />

            <span class="option__title pl-2">{{ $t(props.option.fullName) }}</span>
          </div>
        </template>
      </Multiselect>

      <div class="d-none d-sm-flex">
        <b-link
          :class="{ active: type === 'day' || type === 'custom-daily' }"
          class="btn btn-checkbox px-1-5 mx-1"
          @click.prevent="setTypeEmit('day')"
        >
          {{ $t('staff-calendar.day-button') }}
        </b-link>
        <b-link
          :class="{ active: type === 'week' }"
          class="btn btn-checkbox px-1-5"
          @click.prevent="setTypeEmit('week')"
        >
          {{ $t('staff-calendar.week-button') }}
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css'
import Multiselect from 'vue-multiselect'
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'

import CalendarDatePicker from './CalendarDatePicker'

export default {
  name: 'CalendarNav',
  components: { Multiselect, CalendarDatePicker },
  props: {
    type: {
      type: String,
      default: 'custom-daily'
    },
    templateType: {
      type: String,
      default: 'salon'
    },
    staffList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: moment().format('YYYY-MM-DD')
    }
  },
  data() {
    return {
      now: moment().format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapGetters({
      storeStaff: 'calendar/getSelectedStaff'
    }),
    staffListWithAllEmployee() {
      return this.staffList.concat({
        fullName: this.$t('custom-select-values.all-employee'),
        avatar: null,
        staff_id: 0
      })
    },
    selectedStaff: {
      get() {
        return this.storeStaff
      },
      set(val) {
        this.setSelectedStaff(val)
      }
    }
  },
  methods: {
    ...mapMutations({
      setSelectedStaff: 'calendar/SET_SELECTED_STAFF'
    }),
    setToday() {
      this.$router.push({
        name: this.$route.name,
        params: { type: 'day', date: this.now }
      })
    },
    prevEmit() {
      this.$emit('prev')
    },
    nextEmit() {
      this.$emit('next')
    },
    setTypeEmit(type) {
      if (this.templateType === 'salon' && type === 'day') {
        type = 'custom-daily'
      }

      this.$emit('set-type', type)
    }
  }
}
</script>

<style lang="scss" scoped></style>
