<template>
  <b-modal
    id="client-info-modal"
    header-class="modal-header p-0 border-bottom-0"
    body-class="filterModalBody p-0 px-1-5"
    footer-class="pt-0 px-0 d-block"
    centered
    @shown="clearChoosenData"
  >
    <template v-slot:modal-header="{ close }">
      <div class="top-block w-100">
        <div class="d-flex pt-1-5 justify-content-between">
          <div class="w-100 d-flex justify-content-around"></div>
          <font-awesome-icon
            :icon="['far', 'times']"
            class="icon-close-mobile text-gray mr-1-5"
            @click="close()"
          ></font-awesome-icon>
        </div>
      </div>
    </template>
    <div class="col-12 mb-2">
      <h6 class="text-center">{{ $t('register-client.client-info') }}</h6>
    </div>
    <template v-slot:modal-footer="{ close }">
      <div class="row px-1 px-md-4 mt-0 mb-3 justify-content-center">
        <div class="col-6">
          <button
            class="btn btn-outline-secondary-custom collapsed btn-block btnList py-0-8"
            @click="openAddClientModal"
          >
            <div class="d-flex justify-content-center">
              <span class="text-nowrap fz14 pt-0-2">
                {{ $t('register-client.add-new-client-btn') }}
              </span>
              <font-awesome-icon
                :icon="['far', 'plus']"
                class="text-primary pl-0-5 font-weight-medium"
                style="font-size:24px;"
              ></font-awesome-icon>
            </div>
          </button>
        </div>
        <div class="col-6">
          <button class="btn btn-block btn-primary py-0-8" @click="openClientSearchModal">
            <span class="text-nowrap fz14">
              {{ $t('register-client.search-client-btn') }}
            </span>
          </button>
        </div>

        <div class="col-12 mt-2 mt-sm-4">
          <button class="btn btn-outline-secondary-custom collapsed btn-block py-0-8" @click="close()">
            <span class="fz14">{{ $t('register-client.close') }}</span>
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  methods: {
    openAddClientModal() {
      this.$bvModal.hide('client-info-modal')
      this.$bvModal.show('add-client-modal')
    },
    openClientSearchModal() {
      this.$bvModal.hide('client-info-modal')
      this.$bvModal.show('client-search-modal')
    },
    clearChoosenData() {
      this.$emit('clear-choosen-data', true)
    }
  }
}
</script>
