<template>
  <div class="day-off h-100"></div>
</template>

<script>
export default {
  name: 'EventStaffDayOff'
}
</script>

<style lang="scss"></style>
