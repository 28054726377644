<template>
  <div class="custom-daily-row text-nowrap staffStickyRow">
    <div class="d-inline-block align-bottom empty-blok-header-block" style="min-height:85px;"></div>
    <div
      v-for="(item, key) in staffList"
      :key="`staff-${key}`"
      class="staff-list-item mr-0 py-1-5"
      style="cursor:pointer"
      @click="redirectToStaffCalendar(item)"
    >
      <p class="mb-1 text-black poppins-font font-weight-medium text-truncate">
        {{ item.fullName }}
      </p>
      <img v-if="item.avatar" :src="item.avatar.path" class="staff-avatar-small" alt />
      <img v-else src="@/assets/images/default/default_avatar.png" class="staff-avatar-small" altmapMutations />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
  name: 'HeaderRowStaff',
  props: {
    staffList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...mapMutations({
      setSelectedStaff: 'calendar/SET_SELECTED_STAFF'
    }),
    redirectToStaffCalendar(staff) {
      this.setSelectedStaff(staff)
      this.$router.push(`/calendar/staff/week/${moment().format('YYYY-MM-DD')}`)
    }
  }
}
</script>

<style lang="scss" scoped></style>
