<template>
  <div
    class="event-container d-flex event"
    :class="[
      bgColorClasses,
      event.end ? 'history-booking' : '',
      event.inlineLayout ? 'flow-row align-items-center justify-content-between' : 'flex-column',
      event.status && event.status === 'Successful' ? 'disabledEventContainer' : ''
    ]"
    @click="openEvent()"
  >
    <div :class="event.inlineLayout ? 'w-100' : 'd-flex flex-column h-100'">
      <span v-if="!event.fullDay" class="d-flex justify-content-between">
        <span :class="event.type === 'booking' ? 'text-primary' : 'text-secondary'" class="event-date mb-0 mr-1"
          >{{ event.start_time | startDateFormat }}-{{ event.end_time | endDateFormat }}</span
        >
        <div
          v-if="event.type === 'booking'"
          class="d-flex"
          :class="event.status && event.status === 'Successful' ? 'op-50' : ''"
        >
          <span
            class="ml-auto mr-0"
            :class="event.paid_by === 'Manual' ? 'book_manual_icon' : 'book_quiff_icon'"
          ></span>
          <span
            class="ml-0-5 mr-0"
            :class="event.status === 'Payment success' ? 'book_paid_icon' : 'book_not_paid_icon'"
          ></span>
        </div>
      </span>
      <span :class="!event.inlineLayout ? 'flex-column h-100' : ''" class="d-flex justify-content-between">
        <span
          :class="event.status && event.status === 'Successful' ? 'op-50' : ''"
          class="event-name text-black mb-0 mr-1 text-nowrap text-truncate"
          >{{ eventName }}</span
        >
        <span
          class="event-staff-name mt-auto mb-0 text-gray text-nowrap text-truncate"
          :class="event.status && event.status === 'Successful' ? 'op-50' : ''"
          >{{ event.client_name }}</span
        >
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations } from 'vuex'

export default {
  name: 'Event',
  filters: {
    startDateFormat(val) {
      return moment(val).format('HH:mm')
    },
    endDateFormat(val) {
      return moment(val).format('HH:mm')
    },
    eventNameFormat(val) {
      if (!val.services) return ''
      return val.services.map(e => e.service_title).join(',')
    }
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    eventName() {
      if (this.event.type === 'booking') {
        return this.$options.filters.eventNameFormat(this.event)
      }

      return this.event.title
    },
    bgColorClasses() {
      if (this.event.type === 'booking') {
        return 'bg-white border-primary'
      }

      return 'bg-white-6 border-secondary'
    }
  },
  methods: {
    ...mapMutations({
      setSelectedStaff: 'calendar/SET_SELECTED_STAFF',
      setSelectedDayOffId: 'calendar/SET_SELECTED_DAYSOFF_ID'
    }),
    openEvent() {
      if (this.event.type === 'dayoff') {
        this.setSelectedDayOffId(this.event.id)
        this.$bvModal.show('dayoff-modal')
      } else {
        this.$emit('preview-boooking-by-id', this.event.id)
      }
    }
  }
}
</script>

<style lang="scss"></style>
