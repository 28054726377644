<template>
  <b-modal
    id="client-search-modal"
    header-class="modal-header p-0 border-bottom-0"
    body-class="filterModalBody p-0"
    footer-class="pt-4 px-0 d-block"
    centered
  >
    <template v-slot:modal-header="{ close }">
      <div class="top-block w-100">
        <div class="d-flex pt-1-5 justify-content-between">
          <div class="w-100 d-flex justify-content-around"></div>
          <font-awesome-icon
            :icon="['far', 'times']"
            class="icon-close-mobile text-gray mr-1-5"
            @click="close()"
          ></font-awesome-icon>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col-12 mb-2">
        <h6 class="text-center">
          {{ $t('booking.forms.section.select_client') }}
        </h6>
      </div>
    </div>

    <div class="row text-small">
      <div class="col-12 mb-2">
        <div class="input-group px-1 px-md-4">
          <div class="input-group-prepend">
            <label for="serch-input" class="input-group-text bg-white cursor-pointer">
              <font-awesome-icon
                :icon="['far', 'search']"
                class="icon-search form-control-feedback"
              ></font-awesome-icon>
            </label>
          </div>
          <input
            id="serch-input"
            v-model="searchValue"
            type="text"
            class="form-control calendar-inputs-height"
            :placeholder="$t('register-client.search-placeholder')"
            @input="searchClientByEmail($event)"
          />
        </div>
      </div>

      <div class="col-12">
        <div class="row usersBlock m-0">
          <div
            v-for="(user, id) in users"
            :key="`user-${id}`"
            class="col-12 userBlock d-flex w-100 cursor-pointer py-1"
            :class="choosenUser && choosenUser.id === user.id ? 'choosenClient' : ''"
            style="min-height: 60px"
            @click="chooseUser(user, $event)"
          >
            <div class="row px-1 px-md-3 align-items-center">
              <div class="col-auto">
                <img
                  v-if="!user.avatar"
                  src="@/assets/images/default/default_avatar.png"
                  style="width:48px; height: 48px; border-radius: 7px"
                  alt=""
                />
                <img
                  v-else
                  :src="user.avatar.path"
                  style="width:48px; height: 48px; border-radius: 7px; object-fit: cover"
                  alt=""
                />
              </div>
              <div class="col pl-sm-0">
                <div class="row">
                  <div class="col-12 mb-1">
                    <span class="poppins-font">{{ user.first_name }} {{ user.first_name }}</span>
                  </div>
                  <div class="col-12 d-flex flex-wrap px-0">
                    <div :class="user.phone ? 'col-12 col-sm-auto text-truncate' : 'col-12'" class="roboto-font">
                      <font-awesome-icon
                        :icon="['fas', 'envelope']"
                        class="mr-0-5 mt-0-3 text-gray"
                      ></font-awesome-icon>
                      {{ user.email }}
                    </div>

                    <div v-if="user.phone" class="col-12 col-sm border-left roboto-font text-truncate">
                      <font-awesome-icon
                        :icon="['fas', 'phone-alt']"
                        class="mr-0-5 mt-0-3 text-gray"
                      ></font-awesome-icon>
                      {{ user.phone }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:modal-footer="{ close }">
      <div class="row px-1 px-md-4 mb-3 mt-0 justify-content-center">
        <div class="col-auto col-md-4">
          <button class="btn btn-outline-secondary-custom btn-block collapsed py-0-8" @click="back">
            <span class="fz14">
              {{ $t('register-client.back') }}
            </span>
          </button>
        </div>
        <div class="col">
          <button class="btn btn-block btn-primary py-0-8" @click="submitForm">
            <span class="text-nowrap fz14">
              {{ $t('register-client.next') }}
              <font-awesome-icon
                :icon="['fal', 'long-arrow-right']"
                class="text-white ml-1 align-middle"
                style="font-size:18px;"
              ></font-awesome-icon>
            </span>
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      searchValue: '',
      users: [],
      choosenUser: null,
      error: {
        type: 'error',
        messageI18Key: 'Please choose the client'
      }
    }
  },
  computed: {
    ...mapGetters({
      getUsers: 'user/getUsersByMail'
    })
  },
  methods: {
    ...mapActions({
      fetchSearchUsersByEmails: 'user/searchUsersByEmails',
      setError: 'userMessages/setError'
    }),
    submitForm() {
      if (this.choosenUser) {
        this.$bvModal.hide('client-search-modal')
        this.$bvModal.show('booking-create')
      } else {
        this.setError(this.error)
      }
    },
    back() {
      this.$emit('set-choosen-client-data', null)
      this.searchValue = null
      this.$bvModal.hide('client-search-modal')
      this.$bvModal.show('client-info-modal')
    },
    searchClientByEmail() {
      if (this.searchValue && this.searchValue.length > 3) {
        this.fetchSearchUsersByEmails(this.searchValue).then(() => {
          this.users = this.getUsers
          if (!this.users.length) this.$emit('set-choosen-client-data', null)
        })
      } else this.$emit('set-choosen-client-data', null)
    },
    chooseUser(user, event) {
      if (user) {
        this.choosenUser = user
        this.$emit('set-choosen-client-data', user)
      }
    },
    resetData() {
      this.searchValue = ''
      this.users = []
      this.choosenUser = null
    }
  }
}
</script>
