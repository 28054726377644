<template>
  <div v-if="booking" class="h-100 text-small">
    <div>
      <h6 class="mb-2 w-100 text-center">
        {{ $t('booking.breadcrumbs-details-label') }}
      </h6>
    </div>

    <hr class="mb-0-5" />

    <div class="row px-3 align-items-center">
      <div class="col-12 col-sm mb-1 mb-sm-0">
        <h5 :class="{ 'py-1-5 text-center': booking.status !== 'Reserved' }" class="mb-0">
          <span class="text-primary">
            {{ booking.start_time | dateByFormat('HH:mm') }} - {{ booking.end_time | dateByFormat('HH:mm') }}
          </span>
          <span class="ml-1-5"
            >{{ booking.start_time | dateByFormat('dddd') }} {{ booking.start_time | dateByFormat('DD') }},
          </span>
          <span class="text-gray">{{ booking.start_time | dateByFormat('MMM YYYY') }}</span>
        </h5>
      </div>
      <div class="col-12 col-sm-auto text-right">
        <b-button
          v-if="booking.status === 'Reserved'"
          variant="outline-secondary"
          class="px-2 btn-sm py-1 border"
          @click="toDeclineBooking()"
        >
          <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14 text-danger"></font-awesome-icon>
          <span class="text-black ml-1">
            {{ $t('booking.forms.buttons.decline') }}
          </span>
        </b-button>
      </div>
    </div>

    <hr class="mt-0-5 mb-0" />

    <div class="row px-3">
      <div class="col-md-7 col-lg-8 pt-1-5 pr-0 border-right border-md-down-none">
        <dl class="row mb-0">
          <dt class="col-3">{{ $t('booking.prepview.service') }}</dt>
          <dd class="col-9">{{ servces }}</dd>

          <dt class="col-3">{{ $t('booking.prepview.specialist') }}</dt>
          <dd class="col-9">{{ booking.staff_fullName }}</dd>

          <dt class="col-3">Price:</dt>
          <dd class="col-9">{{ price }} CHF</dd>
        </dl>

        <hr class="mt-0" />

        <dl class="row mb-0">
          <dt class="col-3">{{ $t('booking.prepview.for_whom') }}</dt>
          <dd v-if="age" class="col-9">
            {{ $t('booking.prepview.kids') }} {{ $t(`common.whom_key.${gender}`) }}; {{ age }}
            {{ $t('booking.prepview.year') }}
          </dd>
          <dd v-else class="col-9">{{ $t(`common.whom_key.${gender}`) }}</dd>

          <dt class="col-3">{{ $t('booking.prepview.hair_lenght') }}</dt>
          <dd v-if="length" class="col-9">{{ $t(`common.length_key.${length}`) }}</dd>
          <dd v-else class="col-9">-</dd>
        </dl>

        <hr class="mt-0" />

        <dl class="row mb-0">
          <dt class="col-3">{{ $t('booking.prepview.note') }}</dt>
          <dd v-if="booking.description" class="col-9">
            <span
              v-for="(value, key) in booking.description.split(/\r?\n/)"
              :key="`description-line-${key}`"
              class="d-block"
            >
              {{ value }}
            </span>
          </dd>
          <dd v-else class="col-9">-</dd>
        </dl>
      </div>
      <div class="col-12 col-md-5 col-lg-4">
        <div v-if="booking.client" class="d-flex flex-column align-items-center justify-content-center py-1-5 h-100">
          <img
            :src="getClientImage()"
            alt=""
            class="image--cover mb-1"
            style="width:66px;height:66px;border-radius:7px;"
          />
          <p class="h5 text-black">{{ booking.client_name }}</p>
          <span class="mb-1">
            <font-awesome-icon class="text-gray" :icon="['fas', 'envelope']"></font-awesome-icon>
            {{ booking.client.user.email }}
          </span>
          <span v-if="booking.client.user.phone" class="mb-1-5">
            <font-awesome-icon class="text-gray" :icon="['fas', 'phone-alt']"></font-awesome-icon>
            {{ booking.client.user.phone }}
          </span>
          <b-button variant="outline-secondary" size="sm" class="px-2 py-1 border" @click="toClientInfo()">
            {{ $t('booking.prepview.client_card_link') }}
          </b-button>
        </div>
        <div v-else class="d-flex flex-column align-items-center justify-content-center h-100">
          <font-awesome-icon
            class="mb-1-5"
            :icon="['fas', 'user-slash']"
            style="font-size: 64px;color:#e0e0e0;"
          ></font-awesome-icon>
          <span class="tetx-black">
            {{ $t('booking.prepview.client_not_found_label') }}
          </span>
        </div>
      </div>
    </div>

    <hr class="my-0" />

    <div v-if="booking.reserved_products.length" class="row px-3">
      <div v-for="(item, key) in booking.reserved_products" :key="`product-${key}`" class="col-12">
        <div class="row align-items-center py-1-5">
          <div class="col-auto">
            <img
              v-if="item.product.image"
              :src="item.product.image"
              class="image--cover"
              style="width: 42px;height: 42px;border: solid 1px #e0e0e0;border-radius: 5px;"
              alt=""
            />
          </div>
          <div class="col-7">
            <div class="row">
              <span class="col text-truncate">{{ item.product.title }}</span>
              <span class="col-auto">{{ item.product.price }} CHF</span>
            </div>
            <div v-if="item.variation" class="d-flex align-items-center mt-1">
              <img
                v-if="item.variation.image"
                :src="item.variation.image"
                class="image--cover mr-0-5"
                style="width: 17px;height: 17px;border-radius: 5px;"
                alt=""
              />
              <span class="text-truncate">
                {{ item.variation.title }}
              </span>
            </div>
          </div>
          <div class="col">
            <div class="row no-gutters justify-content-end">
              <div class="col-auto">
                <b-button variant="checkbox" size="sm" class="px-2 py-1" @click="toProductInfo(item.product.id)">
                  <font-awesome-icon :icon="['fas', 'eye']" class="fa-w-14"></font-awesome-icon>
                </b-button>
              </div>
              <div v-if="booking.products_status === 'new'" class="col">
                <button
                  class="btn btn-sm btn-block btn-checkbox text-center ml-1 py-1"
                  @click.prevent="declineBookingProduct(item.product.id)"
                >
                  <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14 mr-md-1"></font-awesome-icon>
                  <span class="d-none d-md-inline">
                    {{ $t('booking.forms.label.decline_product') }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr v-if="key !== booking.reserved_products.length - 1" class="my-0" />
      </div>
    </div>

    <hr class="my-0" />

    <div class="row no-gutters">
      <div class="col-12">
        <div class="row align-items-center px-2 py-1-5 m-0 bg-white-6">
          <div class="col-12 col-md-auto mb-1 mb-md-0">
            <span class="mr-1-5">{{ $t('booking.additional.additional-price-input') }}:</span>
          </div>
          <div class="col-12 col-md-4 d-flex align-items-center mb-1 mb-md-0">
            <div
              class="btn-group mr-1-5 btn-group-toggle"
              :class="{ disabled: disabledAditionPrice }"
              data-toggle="buttons"
            >
              <label class="btn btn-checkbox py-1" :class="{ active: additionalPriceType === 'plus' }">
                <input
                  id="option1"
                  v-model="additionalPriceType"
                  value="plus"
                  type="radio"
                  name="addition_price"
                  autocomplete="off"
                  :disabled="disabledAditionPrice"
                />
                <font-awesome-icon class="" :icon="['far', 'plus']" style="font-size: 18px;"></font-awesome-icon>
              </label>
              <label class="btn btn-checkbox py-1" :class="{ active: additionalPriceType === 'minus' }">
                <input
                  id="option2"
                  v-model="additionalPriceType"
                  value="minus"
                  type="radio"
                  name="addition_price"
                  autocomplete="off"
                  :disabled="disabledAditionPrice"
                />
                <font-awesome-icon class="" :icon="['far', 'minus']" style="font-size: 18px;"></font-awesome-icon>
              </label>
            </div>
            <div class="form-group mb-0">
              <input
                id="additional_price"
                v-model="additional_price"
                v-validate="{
                  decimal: 2,
                  max_value: booking.default_price
                }"
                :data-vv-as="$t('booking.additional.additional-price-input')"
                :disabled="disabledAditionPrice"
                class="form-control"
                type="number"
                name="additional_price"
              />
            </div>
          </div>
          <div class="col-12 col-md">
            <div class="form-group mb-0 d-flex flex-column flex-md-row align-items-start align-items-md-center">
              <span class="pl-md-2 mr-1-5 mb-1 mb-md-0">{{ $t('booking.additional.description-input') }}:</span>
              <input
                id="additionalPriceDescription"
                v-model="additional_price_description"
                v-validate="{
                  max: 255,
                  required: additionalPriceEdit
                }"
                :data-vv-as="$t('booking.additional.description-input')"
                :disabled="disabledAditionPrice"
                class="form-control"
                type="text"
                name="additional_price_description"
              />
              <b-button
                v-if="disabledAditionPrice && booking.status !== 'Successful'"
                variant="checkbox py-0-8 mt-1 mt-md-0 ml-auto ml-md-1-5"
                @click="activateAdditionalPriceEditMode()"
              >
                <font-awesome-icon class="" :icon="['fal', 'edit']" style="font-size: 18px;"></font-awesome-icon>
              </b-button>

              <b-button
                v-if="!disabledAditionPrice && booking.status !== 'Successful'"
                variant="checkbox py-0-8 mt-1 mt-md-0 ml-auto ml-md-1-5"
                @click="updateAddPricesProcess()"
              >
                <font-awesome-icon class="" :icon="['fal', 'save']" style="font-size: 18px;"></font-awesome-icon>
              </b-button>
            </div>
          </div>
        </div>
        <div class="row px-3 px-2">
          <div class="col-12">
            <span v-show="errors.has('additional_price')" class="error-message my-0-5">{{
              errors.first('additional_price')
            }}</span>
          </div>
          <div class="col-12">
            <span v-show="errors.has('additional_price_description')" class="error-message my-0-5">{{
              errors.first('additional_price_description')
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-0" />

    <div class="row px-3 py-1-5">
      <div class="col-12">
        <h5 class="text-center mb-0">
          <span class="pr-1">Price:</span> <span class="text-primary">{{ price }} CHF</span>
        </h5>
      </div>
    </div>

    <hr class="my-0" />

    <div v-if="booking.status === 'Payment success' || booking.status === 'Reserved'" class="row py-2 py-md-4 px-3">
      <div v-if="booking.reserved_products.length" class="col-12 col-sm mb-1 mb-md-0">
        <b-button
          v-if="booking.products_status === 'new'"
          variant="primary"
          class="btn-sm btn-block py-1"
          @click="toPayProducts()"
        >
          <span class="font-weight-medium poppins-font">
            {{ $t('booking.forms.label.pay_for_product', { total: booking.products_price }) }}
          </span>
        </b-button>

        <b-button
          v-else-if="booking.products_status === 'paid'"
          variant="success"
          class="btn-sm btn-block p-e-none py-1"
        >
          <span class="font-weight-medium poppins-font">
            <font-awesome-icon :icon="['far', 'check']" class="fa-w-14 text-white"></font-awesome-icon>
            {{ $t('booking.forms.label.products_paid') }} {{ booking.products_price }} CHF
          </span>
        </b-button>
      </div>

      <div class="col-12 col-sm mb-1 mb-md-0">
        <b-button
          v-if="booking.status === 'Reserved'"
          variant="primary"
          class="btn-sm btn-block py-1"
          @click="toPayBooking()"
        >
          <span class="font-weight-medium poppins-font">
            {{ $t('booking.forms.label.pay_for_the_service', { total: booking.services_price }) }}
          </span>
        </b-button>

        <b-button v-else variant="success" class="btn-sm btn-block p-e-none py-1">
          <span class="font-weight-medium poppins-font">
            <font-awesome-icon :icon="['far', 'check']" class="fa-w-14 text-white"></font-awesome-icon>
            {{ $t('booking.forms.label.services_paid') }} {{ booking.services_price }} CHF
          </span>
        </b-button>
      </div>

      <div class="col-12 col-sm-auto mb-1 mb-md-0">
        <b-button
          variant="primary"
          class="btn-sm py-1"
          :disabled="booking.status !== 'Payment success'"
          @click="toCloseBooking()"
        >
          <span class="font-weight-medium poppins-font">
            {{ $t('booking.forms.buttons.close') }}
          </span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'

export default {
  props: {
    bookingId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      additionalPriceType: null,
      additionalPriceEdit: false,
      additional_price: 0,
      additional_price_description: '',
      booking: null,
      price: 0,
      productsPrice: 0,
      createSuccessMessage: {
        type: this.$t('booking.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.created'
      },
      updateSuccessMessage: {
        type: this.$t('booking.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.updated'
      },
      declineSuccessMessage: {
        type: this.$t('booking.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.decline'
      },
      closeSuccessMessage: {
        type: this.$t('booking.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.closed'
      }
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang',
      accountInfo: 'user/getAccountInfo'
    }),
    gender() {
      return this.booking.parameter_values[0].value
    },
    length() {
      const lengthIds = [21, 22, 23]

      const { parameter_values } = this.booking

      let length = null

      if (parameter_values[1] !== undefined && lengthIds.includes(parameter_values[1].id)) {
        length = parameter_values[1].value
      }

      return length
    },
    age() {
      const { parameter_values } = this.booking
      const ageIds = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

      let age = null

      if (parameter_values[1] !== undefined) {
        if (ageIds.includes(parameter_values[1].id)) {
          age = parameter_values[1].value
        }
      }

      if (parameter_values[2] !== undefined) {
        if (ageIds.includes(parameter_values[2].id)) {
          age = parameter_values[2].value
        }
      }

      return age
    },
    servces() {
      return this.booking.services.map(e => e.service_title).join(',')
    },
    disabledAditionPrice() {
      return !this.additionalPriceEdit || this.booking.status === 'Successful'
    }
  },
  mounted() {
    this.fetchBooking()
    this.resetPreviewBookingId()
  },
  methods: {
    ...mapMutations({
      resetPreviewBookingId: 'calendar/RESET_PREVIEW_BOOKING_ID',
      setPreviewBookingId: 'calendar/SET_PREVIEW_BOOKING_ID',
      setEditedBooking: 'calendar/SET_EDITED_BOOKING',
      removeBookingById: 'calendar/REMOVE_BOOKING_BY_ID'
    }),
    ...mapActions({
      getBookingById: 'booking/getBookingById',
      declineBooking: 'booking/declineBooking',
      closeBooking: 'booking/closeBooking',
      declineProduct: 'booking/declineProduct',
      payForProducts: 'booking/payForProducts',
      payForServices: 'booking/payForServices',
      updateAddPrices: 'booking/updateAddPrices',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    toClientInfo() {
      this.setPreviewBookingId(this.booking.id)

      this.$router.push(`/salon/clients/${this.booking.client_id}`)
    },
    toProductInfo(productId) {
      this.setPreviewBookingId(this.booking.id)

      this.$router.push(`/products/update/${productId}`)
    },
    async fetchBooking() {
      const data = await this.getBookingById(this.bookingId)

      this.setBooking(data)
    },
    setBooking(data) {
      this.booking = data

      const { default_price, app_fee, products_price, paid_by } = data

      this.setPrice(default_price, app_fee, products_price, paid_by)

      if (data.additional_price && data.additional_price_description) {
        this.setAdditionPriceValues(data.additional_price, data.additional_price_description)
      }
    },
    setPrice(default_price, app_fee, products_price, paid_by) {
      const defaultPrice = parseFloat(default_price.replace(/\,/g, ''))
      const appFee = parseFloat(app_fee.replace(/\,/g, ''))
      const productsPrice = products_price ? parseFloat(products_price.replace(/\,/g, '')) : 0

      this.price = ['System', 'Salon'].includes(paid_by)
        ? (defaultPrice + appFee + productsPrice).toFixed(2)
        : defaultPrice
    },
    setAdditionPriceValues(price, description) {
      if (price && description) {
        // Always positive
        this.additional_price = Math.abs(price)
        this.additional_price_description = description

        if (price > 0) {
          this.additionalPriceType = 'plus'
          this.price = this.price + this.additional_price
        } else {
          this.additionalPriceType = 'minus'
          this.price = this.price - this.additional_price
        }
      }
    },
    async desclineProcess() {
      await this.declineBooking(this.booking.id)

      this.removeBookingById(this.booking.id)
      this.setSuccess(this.declineSuccessMessage)
      this.$bvModal.hide('booking-view-modal')
    },
    async closeProcess() {
      const data = await this.closeBooking({ book_id: this.bookingId })

      if (data) {
        this.booking = {
          ...data
        }
        this.setEditedBooking(data)

        this.$bvModal.hide('booking-view-modal')
      }
    },
    toCloseBooking() {
      this.confirmMessage(this.$t('booking.confirm.close_title'))
        .then(value => {
          if (value) {
            this.closeProcess()
          }
        })
        .catch(error => {})
    },
    toDeclineBooking() {
      if (this.booking.status !== 'Reserved') return false

      this.confirmMessage(this.$t('booking.confirm.decline_title'))
        .then(value => {
          if (value) {
            this.desclineProcess()
          }
        })
        .catch(error => {})
    },
    confirmMessage(message) {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [message])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light',
          cancelTitle: `${this.$t('alert_message.cancel_title')}`
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    },
    getClientImage() {
      if (!this.booking.client || !this.booking.client.avatar) {
        return require('@/assets/images/default/default_avatar.png')
      }

      return this.booking.client.avatar.path
    },
    activateAdditionalPriceEditMode() {
      this.additionalPriceEdit = true
    },
    deactivateAdditionalPriceEditMode() {
      this.additionalPriceEdit = false
    },
    additionalDescriptionValidation() {},
    async toPayProducts() {
      const payload = {
        book_id: this.bookingId,
        products_status: 'paid'
      }

      const data = await this.payForProducts(payload)

      if (data) {
        this.booking = {
          ...data
        }

        this.setEditedBooking(data)
      }
    },
    async declineBookingProduct(id) {
      const payload = {
        book_id: this.booking.id,
        products: [id]
      }

      const data = await this.declineProduct(payload)

      if (data) {
        this.booking = {
          ...data
        }

        this.setEditedBooking(data)
      }
    },
    async toPayBooking() {
      const data = await this.payForServices({ book_id: this.booking.id })

      if (data) {
        this.booking = {
          ...data
        }

        this.setEditedBooking(data)
      }
    },
    async updateAddPricesProcess() {
      let validPrice = await this.$validator.validate('additional_price')
      let validPriceDescription = await this.$validator.validate('additional_price_description')

      if (validPrice && validPriceDescription) {
        let additionalPrice = this.additional_price

        if (this.additionalPriceType === 'minus') {
          additionalPrice = -Math.abs(additionalPrice)
        }

        const payload = {
          book_id: this.booking.id,
          additional_price: additionalPrice,
          additional_price_description: this.additional_price_description
        }

        const data = await this.updateAddPrices(payload)

        this.setBooking(data)

        this.additionalPriceEdit = false
      } else if (!this.additional_price && !this.additional_price_description) {
        this.additionalPriceEdit = false
      }
    }
  },
  filters: {
    dateByFormat(val, format) {
      return moment(val).format(format)
    }
  }
}
</script>

<style lang="sass" scoped></style>
