<template>
  <div class="col mt-md-0 mt-2">
    <div class="row">
      <div class="col-12 text-left py-0">
        <span class="text-small text-grey"> {{ age }} {{ $t('common.forms.label.year') }} </span>
      </div>
      <div class="col-12 d-flex py-0">
        <small class="text-gray">
          {{ options.min }}
        </small>
        <VueSlider v-model="age" v-bind="options" :disabled="disabled" class="col px-1-5" />
        <small class="text-gray">
          {{ options.max }}
        </small>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'AgeSlider',
  components: {
    VueSlider
  },
  props: {
    ageValue: {
      type: Number,
      default: 1
    },
    ageValues: {
      type: Array,
      default: () => []
    },
    disabled: Boolean
  },
  data() {
    return {
      age: 1,
      options: {
        min: 1,
        max: 16,
        interval: 1,
        height: 6,
        tooltip: 'none'
      }
    }
  },
  computed: {
    ...mapGetters({
      parametersValues: 'haircutParams/getParametersValues'
    })
  },
  watch: {
    age(newVal) {
      const ageObj = this.ageValues.find(age => age.value === String(newVal))

      if (ageObj !== undefined) this.setAgeEmit(ageObj)
    }
  },
  mounted() {
    if (this.ageValue) {
      this.age = this.ageValue
    }
  },
  methods: {
    setAgeEmit(val) {
      this.$emit('set-age', val)
    }
  }
}
</script>

<style lang="scss">
/* Set the theme color of the component */
$themeColor: #ff6b09;

/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';
</style>
